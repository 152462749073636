import React from 'react';
import { Link } from 'react-router-dom';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pagesPerGroup = 10;
  const currentGroup = Math.ceil(currentPage / pagesPerGroup);
  const startPage = (currentGroup - 1) * pagesPerGroup + 1;
  const endPage = Math.min(currentGroup * pagesPerGroup, totalPages);

  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handleLastPage = () => {
    onPageChange(totalPages);
  };

  const handlePrevGroup = () => {
    if (startPage > 1) {
      onPageChange(startPage - 1);
    }
  };

  const handleNextGroup = () => {
    if (endPage < totalPages) {
      onPageChange(endPage + 1);
    }
  };

  const getClassName = (page) => {
    return `pageNum ${currentPage === page ? 'active' : ''}`;
  };

  return (
    <div className="pagination">
      <button
        className={`firstPage ${currentPage === 1 ? 'disabled' : ''}`}
        onClick={handleFirstPage}
        disabled={currentPage === 1}
      >
        처음
      </button>
      <button
        className={`prevPage ${startPage === 1 ? 'disabled' : ''}`}
        onClick={handlePrevGroup}
        disabled={startPage === 1}
      >
        이전 10페이지
      </button>
      {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
        <Link
          key={startPage + index}
          className={getClassName(startPage + index)}
          to={`?page=${startPage + index}`}
          onClick={() => onPageChange(startPage + index)}
        >
          {startPage + index}
        </Link>
      ))}
      <button
        className={`nextPage ${endPage === totalPages ? 'disabled' : ''}`}
        onClick={handleNextGroup}
        disabled={endPage === totalPages}
      >
        다음 10페이지
      </button>
      <button
        className={`lastPage ${currentPage === totalPages ? 'disabled' : ''}`}
        onClick={handleLastPage}
        disabled={currentPage === totalPages}
      >
        끝
      </button>
    </div>
  );
};

export default Pagination;