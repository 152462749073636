//src/pages/ScholarInfo/ScholarDetail.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from '../../AxiosConfig';

const ScholarDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [scholar, setScholar] = useState(null);
  const [victim, setVictim] = useState([]);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!id) {
      alert('잘못된 접근입니다.');
      window.location.href = '/';
      return;  // 로직을 더 진행하지 않도록 return 추가
    }

    if (!hasFetchedData.current) {
      const fetchData = async () => {
      try {
        hasFetchedData.current = true;
        const response = await axios.get(`/api/scholar/${id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`
          },
        });
        setScholar(response.data.result);

        const victim_idx = response.data.result.victim_idx;
        if (victim_idx) {
          const response2 = await axios.get(`/api/victim/${victim_idx}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
          });
          setVictim(response2.data.result);
        }

      } catch (err) {
        if (err.response.status === 404) {
          alert('장학생 정보를 찾을 수 없습니다.');
          navigate('/scholar-info/list');
        }
      }
    };

    fetchData();
  }

}, []);

  const getName = (families) => {
    const foundFamily = families.find(family => family.relationship_display === "본인");
    return foundFamily ? foundFamily.name : '';
  };

  const getBirthDate = (families) => {
    const foundFamily = families.find(family => family.relationship_display === "본인");
    return foundFamily ? foundFamily.birth_date : '';
  };

  const formatCurrency = (amount) => {
    if (typeof amount !== 'number') {
      amount = parseFloat(amount);
      if (isNaN(amount)) return '0';
    }
    
    return new Intl.NumberFormat('ko-KR', {
      style: 'decimal',
      currency: 'KRW',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  if (!scholar) {
    return <div>Loading...</div>;
  }

  return (
    <div className='subPage'>
      <div className='subTitleWrap borderBtm'>
        <h1 className='subTitle'><strong className='blueText'>{scholar.name}</strong> 상세정보</h1>
      </div>

      <div className='boardDetailWrap'>

        {victim && (
          <div className='victimInfo'>
            <div className='titleBox'>
              <h2 className='title01'>피해자 정보</h2>
            </div>
            <table className='formTable textCenter'>
              <colgroup>
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '25%' }} />
              </colgroup>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>생년월일</th>
                  <th>사고일시</th>
                  <th>피해정도</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{victim.families ? getName(victim.families) : null}</td>
                  <td>{victim.families ? getBirthDate(victim.families) : null}</td>
                  <td>{victim.accidents && victim.accidents[0] ? 
                    victim.accidents[0].accident_date
                    ? dayjs(victim.accidents[0].accident_date).format('YYYY-MM-DD HH:mm')
                    : '-'
                  : null}</td>
                  <td>{victim.accidents && victim.accidents[0] ? victim.accidents[0].damage_level_display : null}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className='titleBox'>
          <h2 className='title01'>장학생 정보</h2>
        </div>
        <div className='scInfoWrap'>
          
          <div className='scInfoBox'>
            <div className='scInfoTitle'><span>인적사항</span></div>
            <div className='scInfoContent'>
              <table className='formTable textCenter'>
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '30%' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>이름</th>
                    <td>{scholar.name}</td>
                    <th>생년월일</th>
                    <td>{scholar.birth_date}</td>
                  </tr>
                  <tr>
                    <th>성별</th>
                    <td>{scholar.gender}</td>
                    <th>연락처</th>
                    <td>{scholar.phone}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
          <div className='scInfoBox'>
            <div className='scInfoTitle'><span>소속정보</span></div>
            <div className='scInfoContent'>
              <table className='formTable textCenter'>
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '30%' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>소속</th>
                    <td colSpan={3}>{scholar.education_level_display}</td>
                  </tr>
                  <tr>
                    <th>학교명</th>
                    <td>{scholar.school_name}</td>
                    <th>학과명</th>
                    <td>{scholar.education_level_display === '중학생' || scholar.education_level_display === '고등학생' ? '' : scholar.department_name}</td>
                  </tr>
                  <tr>
                    <th>학년</th>
                    <td>{scholar.grade}</td>
                    <th>학업상태</th>
                    <td>{scholar.academic_status_display}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='scInfoBox'>
            <div className='scInfoTitle'><span>경제상황</span></div>
            <div className='scInfoContent'>
              <table className='formTable textCenter'>
                <colgroup>
                  <col style={{ width: '100%' }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>{scholar.economic_status_display}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='scInfoBox'>
            <div className='scInfoTitle'><span>가족사항</span></div>
            <div className='scInfoContent'>
              <table className='formTable textCenter'>
                <colgroup>
                  <col style={{ width: '25%' }} />
                  <col style={{ width: '25%' }} />
                  <col style={{ width: '25%' }} />
                  <col style={{ width: '25%' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>이름</th>
                    <th>관계</th>
                    <th>생년월일</th>
                    <th>기타</th>
                  </tr>
                </thead>
                <tbody>
                  {scholar.families.map((family, index) => (
                    <tr key={index}>
                      <td>{family.name}</td>
                      <td>{family.relationship_display}</td>
                      <td>{family.birth_date}</td>
                      <td>{family.etc}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        </div>
        

        <div className='titleBox'>
          <h2 className='title01'>장학금 지급 현황</h2>
        </div>
        <table className='formTable textCenter'>
          <colgroup>
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>지급일</th>
              <th>학교</th>
              <th>학년</th>
              <th>지급금액</th>
            </tr>
          </thead>
          <tbody>
            {scholar.scholarships.map((history, index) => (
              <tr key={index}>
                <td>{history.payment_date}</td>
                <td>{history.school_name}</td>
                <td>{history.grade}</td>
                <td>{formatCurrency(history.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='titleBox'>
          <h2 className='title01'>사업 참여 현황</h2>
        </div>
        <table className='formTable textCenter'>
          <colgroup>
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>참여 사업</th>
              <th>참여년도</th>
              <th>학교</th>
              <th>학년</th>
              <th>결과</th>
              <th>기타</th>
            </tr>
          </thead>
          <tbody>
            {scholar.projects.map((project, index) => (
              <tr key={index}>
                <td>{project.project_name_display}</td>
                <td>{project.participation_year}</td>
                <td>{project.school_name}</td>
                <td>{project.grade}</td>
                <td>{project.result}</td>
                <td>{project.etc}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='formActions'>
          <button onClick={() => navigate('/scholar-info/list')} className='btnBig'>목록으로</button>
          <button onClick={() => navigate(`/scholar-info/${scholar.idx}/edit`)} className='btnBig cancleBtn'>수정하기</button>
        </div>
      </div>
    </div>
  );
};

export default ScholarDetail;
