// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import VictimInfo from './pages/VictimInfo/VictimInfo';
import ScholarInfo from './pages/ScholarInfo/ScholarInfo';
import Statistics from './pages/Statistics/Statistics';
import AccessHistory from './pages/AccessHistory/AccessHistory';
import Login from './pages/Login';
import ErrorPage from './pages/ErrorPage';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider, useAuth } from './AuthContext';
import './App.css';
import './assets/style/default.css';
import './assets/style/style.css';
import './assets/style/modal.css';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // 모달 접근성을 위해 루트 엘리먼트를 설정

function PrivateRoute({ element }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" />;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={
              <>
                <Header />
                <main>
                  <Routes>
                    <Route path="/" element={<Navigate to="/victim-info/list" />} />
                    <Route path="/victim-info/*" element={<PrivateRoute element={<VictimInfo />} />} />
                    <Route path="/scholar-info/*" element={<PrivateRoute element={<ScholarInfo />} />} />
                    <Route path="/statistics" element={<PrivateRoute element={<Statistics />} />} />
                    <Route path="/access-history/*" element={<PrivateRoute element={<AccessHistory />} />} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route path="*" element={<Navigate to="/error" />} />
                  </Routes>
                </main>
                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
