import React, { useState, useRef, useEffect } from "react";
import { useWatch, useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import VictimSearchModal from "./VictimSearchModal";
import Modal from 'react-modal';
import dayjs from 'dayjs';
import axios from '../../AxiosConfig';
import { fetchOptions } from '../../fetchOptions';

Modal.setAppElement('#root');

const ScholarForm = () => {
  const navigate = useNavigate();

  // 옵션 데이터 상태 관리
  const [projectOptions, setProjectOptions] = useState([]);
  const [educationLevelOptions, setEducationLevelOptions] = useState([]);
  const [academicStatusOptions, setAcademicStatusOptions] = useState([]);
  const [economicStatusOptions, setEconomicStatusOptions] = useState([]);
  const [schoolTypeOptions, setSchoolTypeOptions] = useState([]);
  const [relationshipOptions, setRelationshipOptions] = useState([]);

  // 모달 열림 상태 관리
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  // 피해자 이름 검색창에 대한 참조 생성
  const victimSearchInputRef = useRef(null);

  // 포커스 및 완료 상태를 관리할 state
  const [activeFields, setActiveFields] = useState({});
  const [completeFields, setCompleteFields] = useState({});
  const [menuOpen, setMenuOpen] = useState({
    gender: false,
    economic_status: false,
    education_level: false,
    academic_status: false,
    familyMembers: [],
  });

  // 유효성 검사 스키마 정의
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('장학생 이름을 입력하세요'),
    birth_date: Yup.string()
      .required("생년월일을 입력하세요. (YYYY.MM.DD)")
      .matches(/^\d{4}\.\d{2}\.\d{2}$/, "YYYY.MM.DD 형식으로 입력하세요"),
    gender: Yup.object({
      value: Yup.string().required("성별을 선택하세요"),
      label: Yup.string(),
    })
      .nullable()
      .required("성별을 선택하세요."),
    phone: Yup.string().required("연락처를 입력하세요.(숫자만입력)"),
    education_level: Yup.object({
      value: Yup.string().required("소속정보를 선택하세요"),
      label: Yup.string(),
    })
      .nullable()
      .required("소속정보를 선택하세요."),

    // 소속정보에 따른 입력 필드 유효성 검사
    school_name: Yup.string().when("education_level", {
      is: (education_level) => education_level && education_level.label !== "미취학",
      then: (schema) => schema.required("학교명을 입력하세요."),
      otherwise: (schema) => schema,
    }),
    department_name: Yup.string().when("education_level", {
      is: (education_level) => education_level && education_level.label === "대학생",
      then: (schema) => schema.required("학과명을 입력하세요."),
      otherwise: (schema) => schema,
    }),
    grade: Yup.string().when("education_level", {
      is: (education_level) => education_level && education_level.label !== "미취학",
      then: (schema) => schema.required("학년을 입력하세요."),
      otherwise: (schema) => schema,
    }),
    academic_status: Yup.object({
      value: Yup.string().required("학업상태를 선택하세요."),
      label: Yup.string(),
    })
      .nullable()
      .when("education_level", {
        is: (education_level) =>
          education_level && education_level.label !== "미취학",
        then: (schema) => schema.required("학업상태를 선택하세요."),
        otherwise: (schema) => schema.nullable(),
      }),

    economic_status: Yup.object({
      value: Yup.string().required("경제상황을 선택하세요"),
      label: Yup.string(),
    })
      .nullable()
      .required("경제상황을 선택하세요."),

    // 가족 구성원의 생년월일 형식 검사 (입력된 경우에만 검사)
    familyMembers: Yup.array().of(
      Yup.object().shape({
        birth_date: Yup.string()
          .nullable()
          .notRequired()
          .test(
            "is-valid-date",
            "YYYY.MM.DD 형식으로 입력하세요.",
            (value) => !value || /^\d{4}\.\d{2}\.\d{2}$/.test(value)
          ),
      })
    ),

    // 장학금 지급일 형식 검사 (입력된 경우에만 검사)
    scholarshipHistory: Yup.array().of(
      Yup.object().shape({
        payment_date: Yup.string()
          .nullable()
          .notRequired()
          .test(
            "is-valid-date",
            "YYYY.MM.DD 형식으로 입력하세요.",
            (value) => !value || /^\d{4}\.\d{2}\.\d{2}$/.test(value)
          ),
        amount: Yup.string()
          .nullable()
          .notRequired()
          .test(
            "is-valid-amount",
            "숫자만 입력하세요.",
            (value) => !value || /^\d+(,\d{3})*$/.test(value.replace(/,/g, "")) // 쉼표를 제거하고 숫자 형식 검증
          ),
      })
    ),

    // 참여 사업년도 형식 검사 (입력된 경우에만 검사)
    participationHistory: Yup.array().of(
      Yup.object().shape({
        participation_year: Yup.string()
          .nullable()
          .notRequired()
          .test(
            "is-valid-year",
            "YYYY 형식으로 입력하세요.",
            (value) => !value || /^\d{4}$/.test(value)
          ),
      })
    ),
  });

  const handleAmountChange = (index, value) => {
    const numericValue = value.replace(/\D/g, ''); 
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 쉼표 추가
    setValue(`scholarshipHistory.${index}.amount`, formattedValue);
    trigger(`scholarshipHistory.${index}.amount`); 
  };

  // useForm 훅을 사용하여 폼 상태 관리
  const {
    control,
    handleSubmit,
    reset,
    register,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      birth_date: "",
      gender: null,
      phone: "",
      education_level: null, 
      school_name: "",
      department_name: "",
      grade: "",
      academic_status: null,
      economic_status: null,
      familyMembers: [
        { name: "", birth_date: "", relationship: "", etc: "" },
      ],
      scholarshipHistory: [
        { payment_date: "", school_type: "", school_name: "", grade: "", amount: "" },
      ],
      participationHistory: [
        { program: "", participation_year: "", school_name: "", grade: "", result: "", etc: "" },
      ],
    },
  });

  // useFieldArray 훅을 사용하여 반복 가능한 입력 필드 관리
  const { fields: familyMembersFields, append: appendFamilyMember, remove: removeFamilyMember } = useFieldArray({
    control,
    name: "familyMembers",
  });
  const { fields: scholarshipHistoryFields, append: appendScholarshipHistory, remove: removeScholarshipHistory } = useFieldArray({
    control,
    name: "scholarshipHistory",
  });
  const { fields: participationHistoryFields, append: appendParticipationHistory, remove: removeParticipationHistory } = useFieldArray({
    control,
    name: "participationHistory",
  });

  // 성별 선택 옵션
  const genderOptions = [
    { value: "남", label: "남" },
    { value: "여", label: "여" },
  ];

  useEffect(() => {
    const fetchOptionData = async () => {
      try {
        const fetchedProjectOptions = await fetchOptions(1);
        const fetchedEducationLevelOptions = await fetchOptions(2);
        const fetchedAcademicStatusOptions = await fetchOptions(3);
        const fetchedEconomicStatusOptions = await fetchOptions(4);
        const fetchedSchoolTypeOptions = await fetchOptions(5);
        const fetchedRelationshipOptions = await fetchOptions(6);

        setProjectOptions(fetchedProjectOptions);
        setEducationLevelOptions(fetchedEducationLevelOptions);
        setAcademicStatusOptions(fetchedAcademicStatusOptions);
        setEconomicStatusOptions(fetchedEconomicStatusOptions);
        setSchoolTypeOptions(fetchedSchoolTypeOptions);
        setRelationshipOptions(fetchedRelationshipOptions);
      } catch (err) {
        console.error('옵션 데이터 가져오기 실패:', err);
      }
    };

    fetchOptionData();
  }, []);

  // 검색 버튼 클릭 시 모달 열기
  const openModal = () => {
    const victimName = victimSearchInputRef.current ? victimSearchInputRef.current.value.trim() : '';
    
    if (!victimName) {
      victimSearchInputRef.current?.focus(); 
      return;
    }
  
    setIsModalOpen(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // 알림 모달 닫기
  const closeAlertModal = () => {
    setIsAlertModalOpen(false);
    setTimeout(() => {
      victimSearchInputRef.current.focus(); 
    }, 0);
  };

  // 모달에서 피해자 선택 시
  const selectVictim = (victim) => {
    console.log(victim);
    setValue("selectedVictim", victim);
    closeModal();
  };

  // 선택한 옵션 watch로 감지
  const selectedEducationLevel = watch("education_level");
  
  const watchedSchoolType = useWatch({
    control,
    name: "scholarshipHistory",
    defaultValue: [], // 초기값 설정
  });
  
  const watchedParticipationPrograms = useWatch({
    control,
    name: "participationHistory",
    defaultValue: [], // 초기값 설정
  });

  useEffect(() => {
    // education_level이 "미취학"이거나 null이면 관련 필드를 초기화
    if (!selectedEducationLevel || selectedEducationLevel.label === "미취학") {
      setValue("school_name", "");
      setValue("department_name", "");
      setValue("grade", "");
      setValue("academic_status", null);
    } else if (selectedEducationLevel.label !== "대학생") {
      // 대학생이 아니면 학과명 필드를 초기화
      setValue("department_name", "");
    }
  }, [selectedEducationLevel, setValue]);

  useEffect(() => {
    watchedSchoolType.forEach((scholarship, index) => {
      if (scholarship?.school_type?.label === "미취학" && (getValues(`scholarshipHistory.${index}.school_name`) !== "" || getValues(`scholarshipHistory.${index}.grade`) !== "")) {
        setValue(`scholarshipHistory.${index}.school_name`, "");
        setValue(`scholarshipHistory.${index}.grade`, "");
      }
    });
  }, [watchedSchoolType, setValue, getValues]);
  
  useEffect(() => {
    watchedParticipationPrograms.forEach((participation, index) => {
      if (participation?.program?.label === "장학생비전캠프" && getValues(`participationHistory.${index}.result`) !== "") {
        setValue(`participationHistory.${index}.result`, "");
      }
    });
  }, [watchedParticipationPrograms, setValue, getValues]);

  // 입력 필드의 클래스 설정 함수
  const getClassName = (baseClass, name) => {
    let error = errors[name];

    if (name.startsWith("familyMembers")) {
      const parts = name.split(".");
      if (parts.length === 3) {
        const index = parseInt(parts[1], 10);
        const field = parts[2];
        error = errors.familyMembers?.[index]?.[field];
      } else if (parts.length === 2 && parts[0] === "supportInfo") {
        const index = parseInt(parts[1], 10);
        error = errors.supportInfo?.[index]?.supportDate;
      }
    }
    return classNames(baseClass, {
      inputError: error,
      active: activeFields[name],
      complete: completeFields[name],
    });
  };

  // 포커스 핸들러
  const handleFocus = (name) => {
    setActiveFields((prev) => ({ ...prev, [name]: true }));
    if (name in menuOpen) {
      setMenuOpen((prev) => ({ ...prev, [name]: true }));
    }
  };

  // 블러 핸들러
  const handleBlur = (name) => {
    setActiveFields((prev) => ({ ...prev, [name]: false }));
    if (getValues(name)) {
      setCompleteFields((prev) => ({ ...prev, [name]: true }));
    }
    if (name in menuOpen) {
      setMenuOpen((prev) => ({ ...prev, [name]: false }));
    }
    trigger(name); // 블러 시 유효성 검사 트리거
  };

  const onSubmit = async (data) => {
    const selectedVictim = getValues("selectedVictim");
    if (!selectedVictim) {
      setIsAlertModalOpen(true); // 피해자가 선택되지 않았을 때 알림 모달 열기
      return;
    }

    const requestData = {
      victim_idx: selectedVictim.idx,
      name: data.name,
      birth_date: data.birth_date ? dayjs(data.birth_date, 'YYYY.MM.DD').format('YYYY-MM-DD') : '',
      gender: data.gender.value,
      phone: data.phone,
      education_level: data.education_level.value,
      school_name: data.school_name || "",
      department_name: data.department_name || "",
      grade: data.grade || null,
      academic_status: data.academic_status ? data.academic_status.value : null,
      economic_status: data.economic_status.value,
      families: data.familyMembers.map(family => ({
        name: family.name,
        relationship: family.relationship.value,
        birth_date: family.birth_date ? dayjs(family.birth_date, 'YYYY.MM.DD').format('YYYY-MM-DD') : '',
        etc: family.etc || "",
      })),
      projects: data.participationHistory.map((project, index) => ({
        project_idx: project.program.value,
        participation_year: project.participation_year,
        school_name: project.school_name || "", // 여기에 인덱스를 명시하여 school_name을 가져오기
        grade: project.grade || null, // 동일하게 인덱스를 사용
        result: project.result || "",
        etc: project.etc || "",
      })),
      scholarships: data.scholarshipHistory.map((scholarship, index) => ({
        payment_date: scholarship.payment_date ? dayjs(scholarship.payment_date, 'YYYY.MM.DD').format('YYYY-MM-DD') : '',
        school_type: scholarship.school_type.value,
        school_name: scholarship.school_name || "", // 동일하게 인덱스를 사용하여 school_name을 가져오기
        grade: scholarship.grade || null, // 인덱스를 사용하여 grade를 가져오기
        amount: scholarship.amount.replace(/,/g, ''), // 쉼표 제거
      }))
    };

    try {
      const response = await axios.post('/api/scholar/create', requestData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        }
      });

      if (response.status === 201) { // 201 created
        alert("작성이 완료되었습니다.");
        navigate('/scholar-info/list');
      }
    } catch (error) {
        console.error("Error submitting form:", error);
        alert("저장 중 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const handleReset = () => {
    reset();
    navigate("/scholar-info/list");
  };

  return (
    <div className="subPage">
      <div className="subTitleWrap borderBtm">
        <h1 className="subTitle">장학생 정보 입력</h1>
      </div>

      <div className="boardFormWrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="titleBox">
            <h2 className="title01">피해자선택</h2>
          </div>
          <div className="vcSearch">
            <div className="vcSearchWrap">
              <div className="vcSearchBox">
                <input type="text" placeholder="피해자 이름을 입력하세요." className="inputkeyWord" ref={victimSearchInputRef} />
                <button type="button" className="searchBtn" onClick={openModal} ><span>검색</span></button>
              </div>
            </div>
            {getValues("selectedVictim") && (
              <div className="selectedVictim mt10">
                <table className="formTable textCenter">
                  <colgroup>
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "25%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>이름</th>
                      <th>생년월일</th>
                      <th>사고일시</th>
                      <th>피해정도</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{getValues("selectedVictim").name}</td>
                      <td>{getValues("selectedVictim").birth_date}</td>
                      <td>{dayjs(getValues("selectedVictim").last_accident_date).format('YYYY-MM-DD HH:mm')}</td>
                      <td>{getValues("selectedVictim").last_damage_level}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <VictimSearchModal 
            isOpen={isModalOpen} 
            onClose={closeModal} 
            onSelectVictim={selectVictim} 
            initialSearchName={victimSearchInputRef.current?.value || ''} 
          />

          <div className="titleBox">
            <h2 className="title01">장학생 정보 입력</h2>
          </div>
          <div className="titleBox01">
            <h3 className="title02">인적사항</h3>
            <p className="eqText">
              <span className="required">*</span> 필수입력항목입니다.
            </p>
          </div>
          <div className="formBox noWrap">
            <div className={getClassName("inputWrap", "name")}>
              <label>이름<span className="required">*</span></label>
              <input
                type="text"
                {...register("name")}
                placeholder="장학생 이름을 입력하세요"
                autoComplete="off"
                onFocus={() => handleFocus("name")}
                onBlur={() => handleBlur("name")}
              />
              {errors.name && <p className="error">{errors.name.message}</p>}
            </div>
            <div className={getClassName("inputWrap", "birth_date")}>
              <label>생년월일(8자리)<span className="required">*</span></label>
              <input
                type="text"
                {...register("birth_date")}
                placeholder="2000.01.01"
                autoComplete="off"
                onFocus={() => handleFocus("birth_date")}
                onBlur={() => handleBlur("birth_date")}
              />
              {errors.birth_date && <p className="error">{errors.birth_date.message}</p>}
            </div>
            <div className={getClassName("selectWrap", "gender")}>
              <label>성별<span className="required">*</span></label>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={genderOptions}
                    placeholder="성별 선택"
                    classNamePrefix="sv-select"
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                      handleBlur("gender");
                    }}
                    onFocus={() => handleFocus("gender")}
                    onBlur={() => handleBlur("gender")}
                    onMenuOpen={() => handleFocus("gender")}
                    onMenuClose={() => handleBlur("gender")}
                    menuIsOpen={menuOpen.gender}
                  />
                )}
              />
              {errors.gender && (
                <p className="error">{errors.gender.value?.message || errors.gender.message}</p>
              )}
            </div>
            <div className={getClassName("inputWrap", "phone")}>
              <label>연락처<span className="required">*</span></label>
              <input
                type="text"
                {...register("phone")}
                placeholder="연락처를 입력하세요"
                autoComplete="off"
                onFocus={() => handleFocus("phone")}
                onBlur={() => handleBlur("phone")}
              />
              {errors.phone && (
                <p className="error">{errors.phone.message}</p>
              )}
            </div>
          </div>

          <div className="titleBox01">
            <h3 className="title02">소속정보</h3>
          </div>
          <div className="formBox noWrap schoolInput">
            <div className={getClassName("selectWrap", "education_level")}>
              <label>소속선택<span className="required">*</span></label>
              <Controller
                name="education_level"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={educationLevelOptions}
                    placeholder="소속선택"
                    classNamePrefix="sv-select"
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                      handleBlur("education_level");
                    }}
                    onFocus={() => handleFocus("education_level")}
                    onBlur={() => handleBlur("education_level")}
                    onMenuOpen={() => handleFocus("education_level")}
                    onMenuClose={() => handleBlur("education_level")}
                    menuIsOpen={menuOpen.education_level}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                )}
              />
              {errors.education_level && (
                <p className="error">{errors.education_level.message}</p>
              )}
            </div>

            {/* 소속 정보에 따른 입력 필드 조건부 렌더링 */}
            {selectedEducationLevel?.value &&
              selectedEducationLevel?.label !== "미취학" && (

                <>
                  <div className={getClassName("inputWrap", "school_name")}>
                    <label>학교명<span className="required">*</span></label>
                    <input
                      type="text"
                      {...register("school_name")}
                      placeholder="학교명을 입력하세요"
                      onFocus={() => handleFocus("school_name")}
                      onBlur={() => handleBlur("school_name")}
                      autoComplete="off"
                    />
                    {errors.school_name && (
                      <p className="error">{errors.school_name.message}</p>
                    )}
                  </div>

                  {/* 대학생일 경우 */}
                  {selectedEducationLevel?.label === "대학생" && (
                    <>
                      <div className={getClassName("inputWrap", "department_name")}>
                        <label>학과명<span className="required">*</span> </label>
                        <input
                          type="text"
                          {...register("department_name")}
                          placeholder="학과명을 입력하세요"
                          onFocus={() => handleFocus("department_name")}
                          onBlur={() => handleBlur("department_name")}
                          autoComplete="off"
                        />
                        {errors.department_name && (
                          <p className="error">{errors.department_name.message}</p>
                        )}
                      </div>
                    </>
                  )}

                  <div className={getClassName("inputWrap", "grade")}>
                    <label>학년<span className="required">*</span></label>
                    <input
                      type="text"
                      {...register("grade")}
                      placeholder="학년을 입력하세요"
                      onFocus={() => handleFocus("grade")}
                      onBlur={() => handleBlur("grade")}
                      autoComplete="off"
                    />
                    {errors.grade && (
                      <p className="error">{errors.grade.message}</p>
                    )}
                  </div>
                  <div
                    className={getClassName("selectWrap", "academic_status")}
                  >
                    <label>
                      학업상태<span className="required">*</span>
                    </label>
                    <Controller
                      name="academic_status"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={academicStatusOptions}
                          placeholder="학업상태 선택"
                          classNamePrefix="sv-select"
                          isSearchable={false}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            handleBlur("academic_status");
                          }}
                          onFocus={() => handleFocus("academic_status")}
                          onBlur={() => handleBlur("academic_status")}
                          onMenuOpen={() => handleFocus("academic_status")}
                          onMenuClose={() => handleBlur("academic_status")}
                          menuIsOpen={menuOpen.academic_status}
                        />
                      )}
                    />
                    {errors.academic_status && (
                      <p className="error">{errors.academic_status.message}</p>
                    )}
                  </div>

                </>
              )}
          </div>

          <div className="titleBox01">
            <h3 className="title02">경제상황</h3>
          </div>
          <div className="formBox economic">
            <div className={getClassName("selectWrap", "economic_status")}>
              <label>
                경제상황<span className="required">*</span>
              </label>
              <Controller
                name="economic_status"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={economicStatusOptions}
                    placeholder="경제상황 선택"
                    classNamePrefix="sv-select"
                    isSearchable={false}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                      handleBlur("economic_status");
                    }}
                    onFocus={() => handleFocus("economic_status")}
                    onBlur={() => handleBlur("economic_status")}
                    onMenuOpen={() => handleFocus("economic_status")}
                    onMenuClose={() => handleBlur("economic_status")}
                    menuIsOpen={menuOpen.economic_status}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                )}
              />
              {errors.economic_status && (
                <p className="error">{errors.economic_status.message}</p>
              )}
            </div>
          </div>

          <div className="titleBox01">
            <h3 className="title02">가족현황</h3>
          </div>
          <div className="formBox">
          {familyMembersFields.map((member, index) => (
          <div key={member.id} className="familyMember">
            <div className={getClassName("inputWrap", `familyMembers.${index}.name`)}
            >
              <label>이름</label>
              <input
                type="text"
                {...register(`familyMembers.${index}.name`)}
                placeholder="이름을 입력하세요"
                autoComplete="off"
                onFocus={() => handleFocus(`familyMembers.${index}.name`)}
                onBlur={() => handleBlur(`familyMembers.${index}.name`)}
              />
              {errors.familyMembers?.[index]?.name && (
                <p className="error">{errors.familyMembers[index].name.message}</p>
              )}
            </div>
            <div
              className={getClassName("inputWrap", `familyMembers.${index}.birth_date`)}
            >
              <label>생년월일(8자리)</label>
              <input
                type="text"
                {...register(`familyMembers.${index}.birth_date`)}
                placeholder="2000.01.01"
                onFocus={() => handleFocus(`familyMembers.${index}.birth_date`)}
                onBlur={() => handleBlur(`familyMembers.${index}.birth_date`)}
                autoComplete="off"
              />
              {errors.familyMembers?.[index]?.birth_date && (
                <p className="error">{errors.familyMembers[index].birth_date.message}</p>
              )}
            </div>
            <div
              className={getClassName("selectWrap", `familyMembers.${index}.relationship`)}
            >
              <label>관계</label>
              <Controller
                name={`familyMembers.${index}.relationship`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={relationshipOptions}
                    placeholder="관계 선택"
                    classNamePrefix="sv-select"
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                      handleBlur(`familyMembers.${index}.relationship`);
                    }}
                    onFocus={() => handleFocus(`familyMembers.${index}.relationship`)}
                    onMenuOpen={() => handleFocus(`familyMembers.${index}.relationship`)}
                    onMenuClose={() => handleBlur(`familyMembers.${index}.relationship`)}
                    isSearchable={false}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuIsOpen={activeFields[`familyMembers.${index}.relationship`]}
                  />
                )}
              />
              {errors.familyMembers?.[index]?.relationship && (
                <p className="error">{errors.familyMembers[index].relationship.message}</p>
              )}
            </div>
            <div
              className={getClassName("inputWrap", `familyMembers.${index}.etc`)}
            >
              <label>기타</label>
              <input
                type="text"
                {...register(`familyMembers.${index}.etc`)}
                placeholder="기타사항 입력"
                onFocus={() => handleFocus(`familyMembers.${index}.etc`)}
                onBlur={() => handleBlur(`familyMembers.${index}.etc`)}
                autoComplete="off"
              />
              {errors.familyMembers?.[index]?.etc && (
                <p className="error">{errors.familyMembers[index].etc.message}</p>
              )}
            </div>
            <button
              type="button"
              className="rowDelete"
              onClick={() => removeFamilyMember(index)}
            >
              삭제
            </button>
          </div>
        ))}
            <button
              type="button"
              className="rowAdd"
              onClick={() =>
                appendFamilyMember({
                  name: "",
                  birth_date: "",
                  relationship: "",
                  etc: "",
                })
              }
            >
              <span>가족사항 추가</span>
            </button>
          </div>

          <div className="titleBox">
            <h2 className="title01">장학금 지급 현황</h2>
          </div>
          <div className="formBox">
            {scholarshipHistoryFields.map((history, index) => (
              <div key={history.id} className="scholarshipHistory">
                <div className={getClassName("inputWrap", `scholarshipHistory.${index}.payment_date`)}
                >
                  <label>지급일</label>
                  <input
                    type="text"
                    {...register(
                      `scholarshipHistory.${index}.payment_date`
                    )}
                    placeholder="2000.01.01"
                    onFocus={() => handleFocus(`scholarshipHistory.${index}.payment_date`)}
                    onBlur={() => handleBlur(`scholarshipHistory.${index}.payment_date`)}
                    autoComplete="off"
                  />
                  {errors.scholarshipHistory?.[index]?.payment_date && (
                    <p className="error">{errors.scholarshipHistory[index].payment_date.message}</p>
                  )}
                </div>

                <div
                  className={getClassName("selectWrap", `scholarshipHistory.${index}.school_type`)}
                >
                  <label>지급 기관</label>
                  <Controller
                    name={`scholarshipHistory.${index}.school_type`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={schoolTypeOptions}
                        placeholder="지급 기관 선택"
                        classNamePrefix="sv-select"
                        isSearchable={false}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleBlur(`scholarshipHistory.${index}.school_type`);
                        }}
                        onFocus={() => handleFocus(`scholarshipHistory.${index}.school_type`)}
                        onBlur={() => handleBlur(`scholarshipHistory.${index}.school_type`)}
                        onMenuOpen={() => handleFocus(`scholarshipHistory.${index}.school_type`)}
                        onMenuClose={() => handleBlur(`scholarshipHistory.${index}.school_type`)}
                        menuIsOpen={activeFields[`scholarshipHistory.${index}.school_type`]}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    )}
                  />
                  {errors.scholarshipHistory?.[index]?.school_type && (
                    <p className="error">
                      {errors.scholarshipHistory[index].school_type.message}
                    </p>
                  )}
                </div>

                {/* 지급 기관 선택에 따른 입력 필드 조건부 렌더링 */}
                {watchedSchoolType[index]?.school_type?.label !==
                  "미취학" && (
                    <>
                      <div
                        className={getClassName("inputWrap", `scholarshipHistory.${index}.school_name`)}
                      >
                        <label>학교</label>
                        <input
                          type="text"
                          {...register(`scholarshipHistory.${index}.school_name`)}
                          placeholder="학교명을 입력하세요"
                          onFocus={() => handleFocus(`scholarshipHistory.${index}.school_name`)}
                          onBlur={() => handleBlur(`scholarshipHistory.${index}.school_name`)}
                          autoComplete="off"
                        />
                        {errors.scholarshipHistory?.[index]?.school_name && (
                          <p className="error">{errors.scholarshipHistory[index].school_name.message}</p>
                        )}
                      </div>
                      <div
                        className={getClassName("inputWrap", `scholarshipHistory.${index}.grade`)}
                      >
                        <label>학년</label>
                        <input
                          type="text"
                          {...register(`scholarshipHistory.${index}.grade`)}
                          placeholder="학년을 입력하세요"
                          onFocus={() => handleFocus(`scholarshipHistory.${index}.grade`)}
                          onBlur={() => handleBlur(`scholarshipHistory.${index}.grade`)}
                          autoComplete="off"
                        />
                        {errors.scholarshipHistory?.[index]?.grade && (
                          <p className="error">{errors.scholarshipHistory[index].grade.message}</p>
                        )}
                      </div>
                    </>
                  )}

                <div className={getClassName("inputWrap", `scholarshipHistory.${index}.amount`)}
                >
                  <label>지급금액</label>
                  <input
                    type="text"
                    {...register(`scholarshipHistory.${index}.amount`)}
                    placeholder="지급금액을 입력하세요"
                    onFocus={() => handleFocus(`scholarshipHistory.${index}.amount`)}
                    onBlur={() => handleBlur(`scholarshipHistory.${index}.amount`)}
                    autoComplete="off"
                    onChange={(e) => handleAmountChange(index, e.target.value)}
                  />
                  {errors.scholarshipHistory?.[index]?.amount && (
                    <p className="error">{errors.scholarshipHistory[index].amount.message}</p>
                  )}
                </div>
                <button type="button" className="rowDelete" onClick={() => removeScholarshipHistory(index)}>삭제</button>
              </div>
            ))}
            <button
              type="button"
              className="rowAdd"
              onClick={() =>
                appendScholarshipHistory({
                  payment_date: "",
                  school_type: "",
                  school_name: "",
                  grade: "",
                  amount: "",
                })
              }
            >
              <span>지급사항 추가</span>
            </button>
          </div>

          <div className="titleBox">
            <h2 className="title01">사업 참여 현황</h2>
          </div>
          <div className="formBox">
            {participationHistoryFields.map((participation, index) => (
              <div key={participation.id} className="participationHistory">
                <div className={getClassName("selectWrap", `participationHistory.${index}.program`)}
                >
                  <label>참여 사업 선택</label>
                  <Controller
                    name={`participationHistory.${index}.program`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={projectOptions}
                        placeholder="참여 사업 선택"
                        classNamePrefix="sv-select"
                        isSearchable={false}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleBlur(`participationHistory.${index}.program`);
                        }}
                        onFocus={() => handleFocus(`participationHistory.${index}.program`)}
                        onBlur={() => handleBlur(`participationHistory.${index}.program`)}
                        onMenuOpen={() => handleFocus(`participationHistory.${index}.program`)}
                        onMenuClose={() => handleBlur(`participationHistory.${index}.program`)}
                        menuIsOpen={activeFields[`participationHistory.${index}.program`]}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    )}
                  />
                  {errors.participationHistory?.[index]?.program && (
                    <p className="error">{errors.participationHistory[index].program.message}</p>
                  )}
                </div>

                {watchedParticipationPrograms[index]?.program?.value && (
                  <>
                    <div
                      className={getClassName("inputWrap", `participationHistory.${index}.participation_year`)}
                    >
                      <label>참여년도</label>
                      <input
                        type="text"
                        {...register(`participationHistory.${index}.participation_year`)}
                        placeholder="2024"
                        onFocus={() => handleFocus(`participationHistory.${index}.participation_year`)}
                        onBlur={() => handleBlur(`participationHistory.${index}.participation_year`)}
                        autoComplete="off"
                      />
                      {errors.participationHistory?.[index]?.participation_year && (
                        <p className="error">{errors.participationHistory[index].participation_year.message}</p>
                      )}
                    </div>
                    <div
                      className={getClassName("inputWrap", `participationHistory.${index}.school_name`)}
                    >
                      <label>학교</label>
                      <input
                        type="text"
                        {...register(`participationHistory.${index}.school_name`)}
                        placeholder="학교명을 입력하세요"
                        onFocus={() => handleFocus(`participationHistory.${index}.school_name`)}
                        onBlur={() => handleBlur(`participationHistory.${index}.school_name`)}
                        autoComplete="off"
                      />
                      {errors.participationHistory?.[index]?.school_name && (
                        <p className="error">{errors.participationHistory[index].school_name.message}</p>
                      )}
                    </div>
                    <div
                      className={getClassName("inputWrap", `participationHistory.${index}.grade`)}
                    >
                      <label>학년</label>
                      <input
                        type="text"
                        {...register(`participationHistory.${index}.grade`)}
                        placeholder="학년을 입력하세요"
                        onFocus={() => handleFocus(`participationHistory.${index}.grade`)}
                        onBlur={() => handleBlur(`participationHistory.${index}.grade`)}
                        autoComplete="off"
                      />
                      {errors.participationHistory?.[index]?.grade && (
                        <p className="error">{errors.participationHistory[index].grade.message}</p>
                      )}
                    </div>
                    {watchedParticipationPrograms[index]?.program?.label !== "장학생비전캠프" && (
                    <div
                      className={getClassName("inputWrap", `participationHistory.${index}.result`)}
                    >
                      <label>결과</label>
                      <input
                        type="text"
                        {...register(`participationHistory.${index}.result`)}
                        placeholder="결과를 입력하세요"
                        onFocus={() => handleFocus(`participationHistory.${index}.result`)}
                        onBlur={() => handleBlur(`participationHistory.${index}.result`)}
                        autoComplete="off"
                      />
                      {errors.participationHistory?.[index]?.result && (
                        <p className="error">{errors.participationHistory[index].result.message}</p>
                      )}
                    </div>
                    )}
                    <div className={getClassName("inputWrap", `participationHistory.${index}.etc`)}
                    >
                      <label>기타</label>
                      <input
                        type="text"
                        {...register(
                          `participationHistory.${index}.etc`
                        )}
                        placeholder="기타사항을 입력하세요"
                        onFocus={() => handleFocus(`participationHistory.${index}.etc`)}
                        onBlur={() => handleBlur(`participationHistory.${index}.etc`)}
                        autoComplete="off"
                      />
                      {errors.participationHistory?.[index]?.etc && (
                        <p className="error">{errors.participationHistory[index].etc.message}</p>
                      )}
                    </div>
                  </>
                )}

                <button
                  type="button"
                  className="rowDelete"
                  onClick={() => removeParticipationHistory(index)}
                >
                  삭제
                </button>
              </div>
            ))}
            <button
              type="button"
              className="rowAdd"
              onClick={() =>
                appendParticipationHistory({
                  program: "",
                  participation_year: "",
                  school_name: "",
                  grade: "",
                  result: "",
                  etc: "",
                })
              }
            >
              <span>사업 참여 현황 추가</span>
            </button>
          </div>

          {/* 피해자 선택하지 않았을때 경고창 */}
          <Modal
            isOpen={isAlertModalOpen}
            onClose={closeAlertModal}
            contentLabel=""
            className="modalPop confirmPop smallPop"
            overlayClassName="overlay"
          >
            <div className="modalCont">
              <p>피해자 선택은 필수 사항입니다.</p>
              <div className="modalBtnWrap">
                <button
                  onClick={closeAlertModal}
                  className="btnSmall navyBtn"
                >
                  <span>확인</span>
                </button>
              </div>
            </div>
          </Modal>

          <div className="formActions">
            <button type="submit" className="btnBig">작성완료</button>
            <button type="button" onClick={handleReset} className="btnBig cancleBtn">취소</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScholarForm;
