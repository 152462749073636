// src/pages/VictimInfo/VictimInfo.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import VictimList from './VictimList';
import VictimForm from './VictimForm';
import VictimDetail from './VictimDetail';
import VictimEdit from './VictimEdit';

function VictimInfo() {
  // 폼 데이터를 처리하는 함수
  const handleSubmit = async (formData) => {
    try {
      // API 요청 예시
      const response = await fetch('/api/victim', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form submitted successfully:', formData);
        // 성공 시 처리 로직 추가 (예: 성공 메시지, 페이지 이동 등)
      } else {
        console.error('Form submission failed:', response.statusText);
        // 실패 시 처리 로직 추가 (예: 실패 메시지)
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // 예외 처리 로직 추가 (예: 네트워크 오류 메시지)
    }
  };

  return (
    <div className='inner'>
      <Routes>
        {/* 구체적인 라우트를 먼저 선언 */}
        <Route path="list" element={<VictimList />} />
        <Route path="write" element={<VictimForm handleSubmit={handleSubmit} />} />
        <Route path=":id/edit" element={<VictimEdit handleSubmit={handleSubmit} />} />
        <Route path=":id" element={<VictimDetail />} />

        {/* 잘못된 경로에 대한 처리 */}
        <Route path="*" element={<Navigate to="/error" replace />} />      
      </Routes>
    </div>
  );
}

export default VictimInfo;
