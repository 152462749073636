import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import axios from '../../AxiosConfig';
import { fetchOptions } from '../../fetchOptions';

const VictimForm = ({ handleSubmit: parentHandleSubmit }) => {
  const navigate = useNavigate();

  const dateTimeRegex = /^\d{4}\.\d{2}\.\d{2} \d{2}:\d{2}$/;
  const dateRegex = /^\d{4}.\d{2}.\d{2}$/;

  // 유효성 검사 스키마 설정
  const validationSchema = Yup.object().shape({
    accidentDate: Yup.string()
      .required('사고일시를 입력하세요. (YYYY.MM.DD HH:mm)')
      .matches(dateTimeRegex, 'YYYY.MM.DD HH:mm 형식으로 입력하세요'),
    accidentPlace: Yup.string().required('사고장소를 입력하세요'),
    accidentDetails: Yup.string().required('사고내용을 입력하세요'),
    severity: Yup.object({
      value: Yup.string().required('피해 정도를 선택하세요'),
      label: Yup.string(),
    }).nullable().required('피해 정도를 선택하세요'),

    familyMembers: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required('이름을 입력하세요'),
        birth_date: Yup.string()
          .required('생년월일을 입력하세요. (YYYY.MM.DD)')
          .matches(dateRegex, 'YYYY.MM.DD 형식으로 입력하세요'),
        relationship: Yup.object({
          value: Yup.string().required('관계를 선택하세요'),
          label: Yup.string(),
        }).nullable().required('관계를 선택하세요'),
        additionalDetails: Yup.string()
      })
    ),

    // 선택사항 재활보조금 지급현황 유효성 검사
    supportInfo: Yup.array().of(
      Yup.object().shape({
        supportDate: Yup.string()
          .test(
            'is-valid-date',
            'YYYY.MM.DD 형식으로 입력하세요',
            value => !value || dateRegex.test(value)
          )
          .nullable(true),
        supportAmount: Yup.string()
          .test(
            'is-valid-amount',
            '숫자만 입력하세요',
            value => !value || /^\d+$/.test(value.replace(/,/g, ''))
          )
          .nullable(true),
        additionalDetails: Yup.string()
      })
    ),

  });

  // 금액 입력 시 자동으로 쉼표를 추가하는 함수
  const handleSupportAmountChange = (index, value) => {
    const numericValue = value.replace(/\D/g, ''); // 숫자만 남김
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 쉼표 추가
    setValue(`supportInfo.${index}.supportAmount`, formattedValue);
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange', 
    defaultValues: {
      accidentDate: '',
      accidentPlace: '',
      accidentDetails: '',
      severity: null,
      familyMembers: [{ name: '', birth_date: '', relationship: null, additionalDetails: '' }],
      supportInfo: [{ supportDate: '', supportAmount: '', additionalDetails: '' }],
    },
  });

  const { fields: familyMembersFields, append: appendFamilyMember, remove: removeFamilyMember } = useFieldArray({
    control,
    name: 'familyMembers',
  });

  const { fields: supportInfoFields, append: appendSupportInfo, remove: removeSupportInfo } = useFieldArray({
    control,
    name: 'supportInfo',
  });

  const [severityOptions, setSeverityOptions] = useState([]);
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeFields, setActiveFields] = useState({});
  const [completeFields, setCompleteFields] = useState({});
  const [menuOpen, setMenuOpen] = useState({
    severity: false,
    familyMembers: [],
  });

  useEffect(() => {
    const fetchOptionData = async () => {
      try {
        const fetchedSeverityOptions = await fetchOptions(7); // 7: 피해 정도
        const fetchedRelationshipOptions = await fetchOptions(6); // 6: 가족관계

        setSeverityOptions(fetchedSeverityOptions);
        setRelationshipOptions(fetchedRelationshipOptions);
      } catch (err) {
        console.error('옵션 데이터 가져오기 실패:', err);
      }
    };

    fetchOptionData();
  }, []);

  const handleFocus = (name) => {
    setActiveFields((prev) => ({ ...prev, [name]: true }));
    if (name === 'severity') {
      setMenuOpen((prev) => ({ ...prev, [name]: true }));
    }
  };

  const handleBlur = (name) => {
    setActiveFields((prev) => ({ ...prev, [name]: false }));
    if (getValues(name)) {
      setCompleteFields((prev) => ({ ...prev, [name]: true }));
    }
    setMenuOpen((prev) => ({ ...prev, [name]: false }));
    trigger(name);
  };

  const checkForSelfRelationship = (data) => {
    const hasSelfRelationship = data.familyMembers.some(member => member.relationship?.label === '본인');
    return hasSelfRelationship;
  };

  const onSubmit = async (data) => {
    if (!checkForSelfRelationship(data)) {
      setIsModalOpen(true);
      return;
    }

    // 데이터 포맷팅
    const formattedAccidentInfo = {
      accident_date: data.accidentDate ? dayjs(data.accidentDate, 'YYYY.MM.DD HH:mm').format('YYYY-MM-DD HH:mm:ss') : '',
      accident_place: data.accidentPlace,
      accident_desc: data.accidentDetails,
      damage_level: data.severity?.value ? parseInt(data.severity.value, 10) : null,
      etc: data.additionalDetails || '',
    };

    const formattedFamilyMembers = data.familyMembers.map(member => ({
      name: member.name,
      birth_date: member.birth_date ? dayjs(member.birth_date, 'YYYY.MM.DD').format('YYYY-MM-DD') : '',
      relationship: member.relationship?.value ? parseInt(member.relationship.value, 10) : null,
      etc: member.additionalDetails || ''
    }));

    const formattedSupportInfo = data.supportInfo.map(support => ({
      payment_date: support.supportDate ? dayjs(support.supportDate, 'YYYY.MM.DD').format('YYYY-MM-DD') : '',
      amount: support.supportAmount ? parseFloat(support.supportAmount.replace(/,/g, '')) : 0,
      etc: support.additionalDetails || ''
    }));

    try {
      const response = await axios.post(`/api/victim/create`, {
        accidents: [formattedAccidentInfo],
        families: formattedFamilyMembers,
        subsidies: formattedSupportInfo
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        }
      });

      if (response.status === 201) { // 201 created
        alert("작성이 완료되었습니다.");
        navigate('/victim-info/list');
      }
    } catch (error) {
      console.error("Error creating victim info:", error);
      alert("작성 중 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const handleReset = () => {
    reset();
    navigate('/victim-info/list');
  };

  const getClassName = (baseClass, name) => {
    let error = errors[name];
    
    if (name.startsWith("familyMembers")) {
      const parts = name.split(".");
      if (parts.length === 3) {
        const index = parseInt(parts[1], 10);
        const field = parts[2];
        error = errors.familyMembers?.[index]?.[field];
      }
    } else if (name.startsWith("supportInfo")) {
      const parts = name.split(".");
      if (parts.length === 3) {
        const index = parseInt(parts[1], 10);
        const field = parts[2];
        error = errors.supportInfo?.[index]?.[field];
      }
    }

    return classNames(baseClass, {
      inputError: error,
      active: activeFields[name],
      complete: completeFields[name],
    });
  };

  return (
    <div className="subPage">
      <div className="subTitleWrap borderBtm">
        <h1 className="subTitle">피해자 정보입력</h1>
      </div>

      <div className="boardFormWrap">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="titleBox">
            <h2 className="title01">사고정보</h2>
            <p className="eqText">
              <span className="required">*</span> 필수입력항목입니다.
            </p>
          </div>
          <div className="formBox">
            <div className={getClassName("inputWrap", 'accidentDate')}>
              <label>
                사고일시<span className="required">*</span>
              </label>
              <input
                type="text"
                {...register('accidentDate')}
                placeholder="2000.01.01 14:00"
                autoComplete="off"
                onFocus={() => handleFocus('accidentDate')}
                onBlur={() => handleBlur('accidentDate')}
              />
              {errors.accidentDate && (
                <p className="error">{errors.accidentDate.message}</p>
              )}
            </div>
            <div className={getClassName("inputWrap", 'accidentPlace')}>
              <label>
                사고장소<span className="required">*</span>
              </label>
              <input
                type="text"
                {...register('accidentPlace')}
                placeholder="사고장소를 입력하세요"
                autoComplete="off"
                onFocus={() => handleFocus('accidentPlace')}
                onBlur={() => handleBlur('accidentPlace')}
              />
              {errors.accidentPlace && (
                <p className="error">{errors.accidentPlace.message}</p>
              )}
            </div>
            <div className={getClassName("selectWrap", 'severity')}>
              <label>
                피해정도<span className="required">*</span>
              </label>
              <Controller
                name="severity"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={severityOptions}
                    placeholder="피해 정도 선택"
                    classNamePrefix="sv-select"
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                      handleBlur('severity');
                    }}
                    onFocus={() => handleFocus('severity')}
                    onMenuOpen={() => handleFocus('severity')}
                    onMenuClose={() => handleBlur('severity')}
                    isSearchable={false}
                    menuIsOpen={menuOpen.severity} // 메뉴가 열림 상태로 설정
                  />
                )}
              />
              {errors.severity && (
                <p className="error">{errors.severity.value?.message || errors.severity.message}</p>
              )}
            </div>
            <div className={getClassName("textWrap", 'accidentDetails')}>
              <label>
                사고내용<span className="required">*</span>
              </label>
              <textarea
                {...register('accidentDetails')}
                placeholder="사고내용을 입력하세요"
                autoComplete="off"
                onFocus={() => handleFocus('accidentDetails')}
                onBlur={() => handleBlur('accidentDetails')}
              />
              {errors.accidentDetails && (
                <p className="error">{errors.accidentDetails.message}</p>
              )}
            </div>
            <div className={getClassName("textWrap", 'additionalDetails')}>
              <label>기타</label>
              <textarea
                {...register('additionalDetails')}
                placeholder="기타내용을 입력하세요"
                autoComplete="off"
                onFocus={() => handleFocus('additionalDetails')}
                onBlur={() => handleBlur('additionalDetails')}
              />
            </div>
          </div>

          <div className="titleBox">
            <h2 className="title01">가족현황</h2>
          </div>
          <div className="formBox">
            {familyMembersFields.map((member, index) => (
              <div key={member.id} className="familyMember">
                <div className={getClassName("inputWrap", `familyMembers.${index}.name`)}>
                  <label>
                    이름<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    {...register(`familyMembers.${index}.name`)}
                    placeholder="이름을 입력하세요"
                    autoComplete="off"
                    onFocus={() => handleFocus(`familyMembers.${index}.name`)}
                    onBlur={() => handleBlur(`familyMembers.${index}.name`)}
                  />
                  {errors.familyMembers?.[index]?.name && (
                    <p className="error">{errors.familyMembers?.[index]?.name.message}</p>
                  )}
                </div>
                <div className={getClassName("inputWrap", `familyMembers.${index}.birth_date`)}>
                  <label>
                    생년월일<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    {...register(`familyMembers.${index}.birth_date`)}
                    placeholder="2000.01.01"
                    autoComplete="off"
                    onFocus={() => handleFocus(`familyMembers.${index}.birth_date`)}
                    onBlur={() => handleBlur(`familyMembers.${index}.birth_date`)}
                  />
                  {errors.familyMembers?.[index]?.birth_date && (
                    <p className="error">{errors.familyMembers[index].birth_date.message}</p>
                  )}
                </div>
                <div className={getClassName("selectWrap", `familyMembers.${index}.relationship`)}>
                  <label>
                    관계<span className="required">*</span>
                  </label>
                  <Controller
                    name={`familyMembers.${index}.relationship`}
                    control={control}
                    rules={{ required: '관계를 선택하세요' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={relationshipOptions}
                        placeholder="관계 선택"
                        classNamePrefix="sv-select"
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleBlur(`familyMembers.${index}.relationship`);
                        }}
                        onFocus={() => handleFocus(`familyMembers.${index}.relationship`)}
                        onMenuOpen={() => handleFocus(`familyMembers.${index}.relationship`)} // 메뉴가 열릴 때
                        onMenuClose={() => handleBlur(`familyMembers.${index}.relationship`)} // 메뉴가 닫힐 때
                        isSearchable={false}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        menuIsOpen={activeFields[`familyMembers.${index}.relationship`]} // 메뉴 열림 상태
                      />
                    )}
                  />

                  {errors.familyMembers?.[index]?.relationship && (
                    <p className="error">{errors.familyMembers[index].relationship.value?.message || errors.familyMembers[index].relationship.message}</p>
                  )}
                </div>

                <div className={getClassName("inputWrap", `familyMembers.${index}.additionalDetails`)}>
                  <label>기타</label>
                  <input
                    type="text"
                    {...register(`familyMembers.${index}.additionalDetails`)}
                    placeholder="기타사항 입력"
                    onFocus={() => handleFocus(`familyMembers.${index}.additionalDetails`)}
                    onBlur={() => handleBlur(`familyMembers.${index}.additionalDetails`)}
                    autoComplete="off"
                  />
                  {errors.familyMembers?.[index]?.additionalDetails && (
                    <p className="error">{errors.familyMembers[index].additionalDetails.message}</p>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => removeFamilyMember(index)}
                  className="rowDelete"
                >
                  삭제
                </button>
              </div>

            ))}
            <button
              type="button"
              onClick={() =>
                appendFamilyMember({
                  name: '',
                  birth_date: '',
                  relationship: null,
                  additionalDetails: '',
                })
              }
              className="rowAdd"
            >
              <span>가족사항 추가</span>
            </button>
          </div>

          <div className="titleBox">
            <h2 className="title01">재활보조금 지급현황</h2>
          </div>
          <div className="formBox">
            {supportInfoFields.map((support, index) => (
              <div key={support.id} className="supportInfo">
                <div className={getClassName("inputWrap", `supportInfo.${index}.supportDate`)}>
                  <label>지급일</label>
                  <input
                    type="text"
                    {...register(`supportInfo.${index}.supportDate`)}
                    placeholder="2000.01.01"
                    autoComplete="off"
                    onFocus={() => handleFocus(`supportInfo.${index}.supportDate`)}
                    onBlur={() => handleBlur(`supportInfo.${index}.supportDate`)}
                  />
                  {errors.supportInfo?.[index]?.supportDate && (
                    <p className="error">{errors.supportInfo[index].supportDate.message}</p>
                  )}
                </div>
                <div className={getClassName("inputWrap", `supportInfo.${index}.supportAmount`)}>
                  <label>지급금액(원)</label>
                  <input
                    type="text"
                    {...register(`supportInfo.${index}.supportAmount`)}
                    placeholder="재활보조금(숫자만 입력)"
                    autoComplete="off"
                    onFocus={() => handleFocus(`supportInfo.${index}.supportAmount`)}
                    onBlur={() => handleBlur(`supportInfo.${index}.supportAmount`)}
                    onChange={(e) => handleSupportAmountChange(index, e.target.value)}
                  />
                  {errors.supportInfo?.[index]?.supportAmount && (
                    <p className="error">{errors.supportInfo[index].supportAmount.message}</p>
                  )}
                </div>

                <div className={getClassName("inputWrap", `supportInfo.${index}.additionalDetails`)}>
                  <label>기타</label>
                  <input
                    type="text"
                    {...register(`supportInfo.${index}.additionalDetails`)}
                    placeholder="기타사항 입력"
                    autoComplete="off"
                    onFocus={() => handleFocus(`supportInfo.${index}.additionalDetails`)}
                    onBlur={() => handleBlur(`supportInfo.${index}.additionalDetails`)}
                  />
                  {errors.supportInfo?.[index]?.additionalDetails && (
                    <p className="error">{errors.supportInfo[index].additionalDetails.message}</p>
                  )}
                </div>

                <button
                  type="button"
                  onClick={() => removeSupportInfo(index)}
                  className="rowDelete"
                >
                  삭제
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() =>
                appendSupportInfo({
                  supportDate: '',
                  supportAmount: '',
                  additionalDetails: '',
                })
              }
              className="rowAdd"
            >
              <span>재활보조금 지급 내역 추가</span>
            </button>
          </div>

          <div className="formActions">
            <button type="submit" className="btnBig">작성완료</button>
            <button type="button" onClick={handleReset} className="btnBig cancleBtn">취소</button>
          </div>

        </form>
      </div>

      {/* 본인정보 입력하지 않았을 때 경고창    */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        contentLabel='본인정보입력 확인 메시지'
        className='modalPop confirmPop smallPop'
        overlayClassName='overlay'
      >
        <div className='modalCont'>
          <p>피해자 본인의 정보는 반드시 입력해야만 합니다.</p>
          <div className='modalBtnWrap'>
            <button onClick={() => setIsModalOpen(false)} className='btnSmall navyBtn'>
              <span>확인</span>
            </button>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default VictimForm;
