//src/pages/ScholarInfo/ScholarList.js
import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import axios from '../../AxiosConfig';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import Pagination from '../../components/Common/Pagination';
import FileUploadModal from '../../components/Common/FileUploadModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchOptions } from '../../fetchOptions';

Modal.setAppElement('#root');

const ScholarList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [scholarList, setScholarList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const initialFetchDone = useRef(false);
  const [tmpEducations, setTmpEducations] = useState([]);
  
  // 검색 필터 상태
  const [filters, setFilters] = useState({
    studentName: '',
    startDate: null,
    endDate: null,
    selectedProjects: [],
    selectedGrades: []
  });

  // 검색 필터 옵션
  const gradeOptions = [
    '미취학', '초1', '초2', '초3', '초4','초5', '초6', '중1', '중2', '중3', '고1', '고2', '고3','대1', '대2', '대3', '대4'
  ];

  // 옵션 데이터 상태 관리
  const [projectOptions, setProjectOptions] = useState([]);
  const [educationLevelOptions, setEducationLevelOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);

  const setEducations = () => {
    console.log("setEducations");
    const educations = [];
    filters.selectedGrades.map((grade) => {
      const tmpGrade = {};
      if(grade === '미취학') {
        tmpGrade['level'] = educationLevelOptions.find((item) => item.label === '미취학').value;
        tmpGrade['grade'] = '';
      } else {
        // grade 값이 미취학이 아닐 경우
        let levelData = {};
        switch(grade.slice(0,1)) {
          case '초':
            levelData = educationLevelOptions.find((item) => item.label === '초등학생');
            break;
          case '중':
            levelData = educationLevelOptions.find((item) => item.label === '중학생');
            break;
          case '고':
            levelData = educationLevelOptions.find((item) => item.label === '고등학생');
            break;
          case '대':
            levelData = educationLevelOptions.find((item) => item.label === '대학.');
            break;
          default:
            levelData = null;
        }
        if(levelData) {
          tmpGrade['level'] = levelData.value;
          tmpGrade['grade'] = parseInt(grade.slice(1,2));
        }
      }
      // tmpGrade가 빈 값이 아닐 경우 tmpEducations 추가
      if(tmpGrade['level']) {
        educations.push(tmpGrade);
      }
    });
    console.log("setEducations");
    return educations;
  };

  const fetchScholars = async (page = currentPage, page_size = itemsPerPage) => {
    const tmpEducations = setEducations();
    try {
      const requestData = {
        page,
        page_size,
        name: filters.studentName,
        payment_start: filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : '',
        payment_end: filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : '',
        projects: filters.selectedProjects, // 프로젝트 선택 데이터
        educations: tmpEducations // 교육 레벨 및 학년 정보
      };
      const response = await axios.post('/api/scholars', requestData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        }
      });

      setScholarList(response.data.result.scholars);
      setTotalItems(response.data.result.total_datas);
      setTotalPages(response.data.result.total_pages);
      setSelectedItems([]);
    } catch (err) {
      console.log("err", err);
    }
  };

  // 초기 로딩 시 데이터 불러오기
  useEffect(() => {
    const fetchOptionData = async () => {
      try {
        const fetchedProjectOptions = await fetchOptions(1);
        const fetchedEducationLevelOptions = await fetchOptions(2);
        
        setProjectOptions(fetchedProjectOptions);
        setEducationLevelOptions(fetchedEducationLevelOptions);

        setOptionsLoaded(true);
      } catch (err) {
        console.error('옵션 데이터 가져오기 실패:', err);
      }
    };
    if (!initialFetchDone.current) {
      fetchOptionData();
      fetchScholars();
      initialFetchDone.current = true;
    }
  }, []);

  const handleSearchSubmit = () => {
    setCurrentPage(1);
    fetchScholars(1, itemsPerPage);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchScholars(page, itemsPerPage);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value); 
    setCurrentPage(1);
    fetchScholars(1, e.target.value);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allIds = scholarList.map((scholar) => scholar.idx);
      setSelectedItems(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (id) => {
    setSelectedItems(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(item => item !== id)
        : [...prevSelected, id]
    );
  };

  const handleDeleteSelected = () => {
    console.log(selectedItems);
    if (selectedItems.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteSelected = async () => {
    
    try {
      if (selectedItems.length === 1) {
          // 선택된 항목이 하나일 때
          const selectedId = selectedItems[0];
          await axios.delete(`/api/scholar/delete/${selectedId}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
      } else {
        // 선택된 항목이 여러 개일 때
        await axios.delete(`/api/scholars/delete`, {
          data: {
            scholar_ids: selectedItems,
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`
          },
        });
      }

      // 삭제 후 상태 업데이트 및 알림
      fetchScholars();
      setIsDeleteModalOpen(false);
      alert("선택한 항목이 삭제되었습니다.");
    } catch (err) {
        console.error("삭제 중 오류 발생:", err);
        alert("삭제 중 오류가 발생했습니다.");
        
    }
  };

  const handleUploadSuccess = () => {
    fetchScholars();
  };

  const handleDownload = async () => {

    try {
      const tmpEducations = setEducations();
      const requestData = {
        name: filters.studentName,
        payment_start: filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : '',
        payment_end: filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : '',
        projects: filters.selectedProjects, // 프로젝트 선택 데이터
        educations: tmpEducations // 교육 레벨 및 학년 정보
      };
      const response = await axios.post('/api/scholars/download', requestData, {
        responseType: 'blob', // 서버에서 파일을 blob 형식으로 받을 것을 명시
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        }
      });

      // 파일 이름을 서버 응답의 Content-Disposition 헤더에서 추출 (옵션)
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'scholar_list.xlsx';
      if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        const matches = /filename="(.+)"/.exec(contentDisposition); 
        if (matches != null && matches[1]) {
          filename = matches[1];
        }
      }

      // Blob 객체 생성
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });

      // 브라우저에서 파일 다운로드를 트리거
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      // 다운로드 후 링크 제거
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

    } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
        alert("파일 다운로드 중 오류가 발생했습니다.");
    }
  };

  const formatCurrency = (amount) => {
    if (typeof amount !== 'number') {
      amount = parseFloat(amount);
      if (isNaN(amount)) return '0';
    }
    
    return new Intl.NumberFormat('ko-KR', {
      style: 'decimal',
      currency: 'KRW',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleDateChange = (date, name) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: date
    }));
  };

  const handleProjectCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        selectedProjects: [...prevFilters.selectedProjects, value]
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        selectedProjects: prevFilters.selectedProjects.filter((item) => item !== value)
      }));
    }
  };

  const handleGradeCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        selectedGrades: [...prevFilters.selectedGrades, value]
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        selectedGrades: prevFilters.selectedGrades.filter((item) => item !== value)
      }));
    }
  };

  const handleSearch = () => {
    fetchScholars();
  };

  return (
    <div className='subPage'>
      <div className='subTitleWrap borderBtm'>
        <h1 className='subTitle'>장학생 목록</h1>
        <div className='rightBtnWrap'>
          <Link to="/scholar-info/write" className='button navyBtn writeBtn'><span>장학생 정보입력</span></Link>
          <button className='button uploadBtn' onClick={() => setIsModalOpen(true)}><span>엑셀 업로드</span></button>
          <button className='button downBtn' onClick={handleDownload}><span>엑셀 다운로드</span></button>
        </div>
      </div>
      
      <div className="searchFilterBox">
        <div className="searchFilterItem">
          <span className='filterTit w80'>장학생 이름</span>
          <div className='filterBox'>
          <input
            type="text"
            className='inputText'
            name="studentName"
            value={filters.studentName}
            onChange={handleInputChange}
          />
          </div>
        </div>
        <div className="searchFilterItem">
          <span className='filterTit'>지급일</span>
          <div className='filterBox'>
          <DatePicker
            selected={filters.startDate}
            onChange={(date) => handleDateChange(date, 'startDate')}
            dateFormat="yyyy-MM-dd"
            placeholderText="시작일"
            maxDate={new Date()}        // 오늘 날짜까지 선택 가능
          />
          <span> ~ </span>
          <DatePicker
            selected={filters.endDate}
            onChange={(date) => handleDateChange(date, 'endDate')}
            dateFormat="yyyy-MM-dd"
            placeholderText="종료일"
            minDate={filters.startDate} // 시작일 이후로만 종료일 선택 가능
            maxDate={new Date()}        // 오늘 날짜까지 선택 가능
          />
          </div>
        </div>
        <div className="searchFilterItem w100">
          <span className='filterTit w80'>참여사업</span>
          <div className='filterBox'>
          {projectOptions.length > 0 && optionsLoaded && projectOptions.map((project) => (
            <div key={project.value}>
              <label>
                <input
                  type="checkbox"
                  value={project.value}
                  onChange={handleProjectCheckboxChange}
                />
                {project.label}
              </label>
            </div>
          ))}
          </div>
        </div>
        <div className="searchFilterItem w100">
          <span className='filterTit w80'>학년</span>
          <div className='filterBox'>
                      {gradeOptions.map((grade) => (
            <div key={grade}>
              <label>
                <input
                  type="checkbox"
                  value={grade}
                  onChange={handleGradeCheckboxChange}
                />
                {grade}
              </label>
            </div>
          ))}
          </div>
        </div>
        <button onClick={handleSearch} className="searchBtn"><span>검색</span></button>
      </div> 

      <div className='boardListTop'>
        <div className='boardTopLeft'>
          <div className='pageNum'>
            <span>총 {totalItems}건 / {currentPage} 페이지</span>
          </div>
          <div className='pgNumSelect'>
            <label htmlFor="itemsPerPage" className='hidden'>페이지당 항목 수: </label>
            <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value={5}>5개씩 보기</option>
              <option value={10}>10개씩 보기</option>
              <option value={20}>20개씩 보기</option>
            </select>
          </div>
          <button onClick={handleDeleteSelected} className='button'><span>선택 삭제</span></button>
        </div>
      </div>

      <div className='boardList'>
        <table className='boardTable'>
          <colgroup>
            <col style={{ width: '4%' }} />
            <col style={{ width: '6%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th><input type="checkbox" className='allSelect' onChange={handleSelectAll} checked={selectAll} /></th>
              <th className='listNum'>No</th>
              <th>이름</th>
              <th>생년월일</th>
              <th>연락처</th>
              <th>학력</th>
              <th>장학금</th>
            </tr>
          </thead>
          <tbody>
            {scholarList.map((scholar, index) => (
              <tr key={scholar.idx}>
                <td><input type="checkbox" checked={selectedItems.includes(scholar.idx)} onChange={() => handleCheckboxChange(scholar.idx)} /></td>
                <td>{startIndex + index + 1}</td>
                <td><Link to={`/scholar-info/${scholar.idx}`}>{scholar.name}</Link></td>
                <td>{scholar.birth_date}</td>
                <td>{scholar.phone}</td>
                <td>{scholar.education_level_display}</td>
                <td>{formatCurrency(scholar.total_scholarship_amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

      <FileUploadModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        uploadEndpoint="/api/scholars/upload"
        onUploadSuccess={handleUploadSuccess} // 업로드 성공 시 호출할 함수
      />

      {/* 삭제 확인 모달 */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="삭제 확인"
        className="modalPop confirmPop"
        overlayClassName="overlay"
      >
        <div className='modalTitle'>
          <h2>장학생 목록 선택 삭제</h2>
        </div>
        <div className='modalCont'>
          <p>삭제된 데이터는 복구되지 않습니다.<br/>선택한 장학생을 삭제하시겠습니까?</p>
          <div className='modalBtnWrap'>
            <button onClick={confirmDeleteSelected} className='btnSmall navyBtn'><span>확인</span></button>
            <button onClick={() => setIsDeleteModalOpen(false)} className='btnSmall'><span>취소</span></button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScholarList;
