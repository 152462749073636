// src/components/Header.js
import React from 'react';
import axios from '../AxiosConfig';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

function Header() {
  const navigate = useNavigate();
  const location = useLocation(); // 현재 URL 경로를 얻기 위해 useLocation 사용
  const { logout } = useAuth();

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const handleLogout = () => {
    try {
      // authorization 헤더에 토큰을 담아 로그아웃 요청
      console.log(localStorage);
      const response = axios.post('/api/logout', {
        refresh: localStorage.getItem("refresh_token"),
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        }
      });
      logout();
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  // 현재 경로와 일치하거나 하위 경로인 경우 active 클래스를 추가하는 함수
  const isActive = (path) => {
    return location.pathname.startsWith(path) ? 'active' : '';
  };

  return (
    <header>
      <div className='inner'>
        <div className="logoWrap">
          <Link to="/" className="logo"><span className="hidden">고속도로장학재단</span></Link>
        </div>
        <nav>
          <ul className="menu">
            <li><Link to="/victim-info/list" className={isActive('/victim-info')}>피해자 정보</Link></li>
            <li><Link to="/scholar-info/list" className={isActive('/scholar-info')}>장학생 정보</Link></li>
            <li><Link to="/statistics" className={isActive('/statistics')}>통계</Link></li>
            <li><Link to="/access-history/list" className={isActive('/access-history')}>접속 이력</Link></li>
            <li><button onClick={handleLogout} className='logout'>로그아웃</button></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
