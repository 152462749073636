//src/pages/ErrorPage.js
import React from 'react';
import { Link } from 'react-router-dom';

function ErrorPage() {
  return (
    <div className='errorPage'>
      <div className='inner'>
        <h1>원하시는 페이지를 찾을 수 없어요!</h1>
        <p>찾으려는 웹페이지의 주소가 잘못 입력되었거나, 주소의 변경 또는 삭제로 인해 사용하실 수 없습니다.<br/>
        입력하신 링크정보, 접근 권한 등을 다시 한번 확인해 주세요.
        </p>
        <Link to="/" className='goHome btnBig btnArw'><span>홈으로 이동</span></Link>
      </div>
    </div>
  );
}

export default ErrorPage;
