//src/pages/AccessHistory/AccessPermission.js
import React, { useState, useEffect, useRef } from 'react';
import axios from '../../AxiosConfig';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Pagination from '../../components/Common/Pagination';

Modal.setAppElement('#root');

function AccessPermission() {
    const [ipList, setIpList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [newIp, setNewIp] = useState({ part1: '', part2: '', part3: '', part4: '' });

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [selectedIpForModal, setSelectedIpForModal] = useState('');
    const initialFetchDone = useRef(false);

    const fetchIpList = async (page = currentPage, page_size = itemsPerPage, ip = searchTerm) => {
        try {
          const response = await axios.get(`/api/allowed_ip`, {
            params: {
              page,
              page_size,
              ip,
            },
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
          });
          setIpList(response.data.result.allowed_ips);
          setTotalItems(response.data.result.total_datas);
          setTotalPages(response.data.result.total_pages);
          setSelectedItems([]);
        } catch (err) {
          console.log("err", err);
        }
    };
    
    // 초기 로딩 시 데이터 불러오기
    useEffect(() => {
        if (!initialFetchDone.current) {
            fetchIpList();
            initialFetchDone.current = true;
        }
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
    const handleSearchSubmit = () => {
        setCurrentPage(1);
        fetchIpList(1, itemsPerPage, searchTerm);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleCheckboxChange = (idx) => {
        setSelectedItems(prevSelected =>
            prevSelected.includes(idx)
                ? prevSelected.filter(item => item !== idx)
                : [...prevSelected, idx]
        );
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allIds = ipList.map(ip => ip.idx);
            setSelectedItems(allIds);
        }
        setSelectAll(!selectAll);
    };

    const handleDeleteSelected = () => {
        if (selectedItems.length === 0) return;
        setSelectedIpForModal(selectedItems.map(idx => ipList.find(ip => ip.idx === idx).ip).join(', '));
        setIsDeleteModalOpen(true);
    };

    const confirmDeleteSelected = async () => {
        
      try {
        if (selectedItems.length === 1) {
            // 선택된 항목이 하나일 때
            const selectedId = selectedItems[0];
            await axios.delete(`/api/allowed_ip/delete/${selectedId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        } else {
          // 선택된 항목이 여러 개일 때
          await axios.delete(`/api/allowed_ips/delete`, {
            data: {
                ip_ids: selectedItems,
            },
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
          });
        }
  
        // 삭제 후 상태 업데이트 및 알림
        fetchIpList();
        setSelectAll(false);
        setIsDeleteModalOpen(false);
        alert("선택한 항목이 삭제되었습니다.");
      } catch (err) {
        console.error("삭제 중 오류 발생:", err);
        alert("삭제 중 오류가 발생했습니다.");
      }
    };

    const handleAddIp = () => {
        const { part1, part2, part3, part4 } = newIp;
        if (!part1 || !part2 || !part3 || !part4) {
            if (!part1) document.getElementById('part1').focus();
            else if (!part2) document.getElementById('part2').focus();
            else if (!part3) document.getElementById('part3').focus();
            else if (!part4) document.getElementById('part4').focus();
            return;
        }
        const newIpAddress = `${part1}.${part2}.${part3}.${part4}`;
        setSelectedIpForModal(newIpAddress);
        setIsAddModalOpen(true);
    };

    const confirmAddIp = async () => {
    
        const newIpAddress = selectedIpForModal;
        if(newIpAddress === '') return;
        
        try {
            const response = await axios.post(`/api/allowed_ip/create`, {
              ip: newIpAddress
            }, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              }
            });
    
            if (response.status === 201) { // 201 created
                alert("완료되었습니다.");
                setNewIp({ part1: '', part2: '', part3: '', part4: '' });
                fetchIpList();
                setIsAddModalOpen(false);
            }
        } catch (error) {
            console.error("Error", error);
            alert("저장 중 오류가 발생했습니다. 다시 시도해 주세요.");
        }
    };

    const handleIpInputChange = (e) => {
        const { id, value } = e.target;
        if (isValidIpPart(value) || value === '') {
            setNewIp(prevState => ({
                ...prevState,
                [id]: value
            }));
            if (value.length === 3) {
                switch (id) {
                    case 'part1':
                        document.getElementById('part2').focus();
                        break;
                    case 'part2':
                        document.getElementById('part3').focus();
                        break;
                    case 'part3':
                        document.getElementById('part4').focus();
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const isValidIpPart = (part) => {
        const num = parseInt(part, 10);
        return num >= 0 && num <= 255;
    };

    const startIndex = (currentPage - 1) * itemsPerPage;

    return (
        <div className='subPage'>
            <div className='subTitleWrap borderBtm'>
                <h1 className='subTitle'>아이피 허용 관리</h1>
                <div className='rightBtnWrap'>
                    <Link to="/access-history/list" className='button navyBtn accessListBtn'><span>접속 이력 보기</span></Link>
                </div>
            </div>

            <div className="ipPermissionCnts">

                {/*  아이피 접속 허용 목록 */}
                <div className='ipList'>
                    <div className='titleBox mt0'>
                        <h2 className='title01'>IP 접속 허용 목록</h2>
                    </div>

                    <div className='boardListTop mt10'>
                        <div className='boardTopLeft'>
                            <div className='pageNum'>
                                <span>총 {totalItems}건 / {currentPage} 페이지</span>
                            </div>
                            <div className='pgNumSelect'>
                                <label htmlFor="itemsPerPage" className='hidden'>페이지당 항목 수: </label>
                                <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                    <option value={5}>5개씩 보기</option>
                                    <option value={10}>10개씩 보기</option>
                                    <option value={20}>20개씩 보기</option>
                                </select>
                            </div>
                        </div>
                        <div className='boardTopRight'>
                            <button className='button' onClick={handleDeleteSelected}><span>선택한 IP 차단</span></button>
                            <div className='searchWrap'>
                                <div className='searchBox'>
                                    <input
                                        type="text"
                                        placeholder="아이피 검색"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        onKeyDown={handleKeyPress}
                                        className='inputkeyWord'
                                    />
                                    <button className='searchBtn' onClick={handleSearchSubmit}><span className="hidden">검색</span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='boardList'>
                        <table className='boardTable'>
                            <colgroup>
                                <col style={{ width: '4%' }} />
                                <col style={{ width: '6%' }} />
                                <col style={{ width: '90%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th><input type="checkbox" className='allSelect' onChange={handleSelectAll} checked={selectAll} /></th>
                                    <th className='listNum'>No</th>
                                    <th>IP주소</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ipList.map((ip, index) => (
                                    <tr key={ip.idx}>
                                        <td><input type="checkbox" checked={selectedItems.includes(ip.idx)} onChange={() => handleCheckboxChange(ip.idx)} /></td>
                                        <td>{startIndex + index + 1}</td>
                                        <td>{ip.ip}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>

                {/*  아이피 접속 허용 */}
                <div className='newIp'>
                    {/* <div className='titleBox'>
                        <h2 className='title01'>IP 접속 허용</h2>
                    </div> */}
                    <div className='ipInputWrap'>
                        <label htmlFor='ipAddress' className='hidden'>IP 입력</label>
                        <div className='ipInputBox'>
                            <input type='text' id='part1' value={newIp.part1} onChange={handleIpInputChange} maxLength={3} />
                            <span>-</span>
                            <input type='text' id='part2' value={newIp.part2} onChange={handleIpInputChange} maxLength={3} />
                            <span>-</span>
                            <input type='text' id='part3' value={newIp.part3} onChange={handleIpInputChange} maxLength={3} />
                            <span>-</span>
                            <input type='text' id='part4' value={newIp.part4} onChange={handleIpInputChange} maxLength={3} />
                        </div>
                        <button className='button blueBtn' onClick={handleAddIp}><span>IP 접속 허용</span></button>
                    </div>
                </div>

                {/* 접속 차단 확인 모달 */}
                <Modal
                    isOpen={isDeleteModalOpen}
                    onRequestClose={() => setIsDeleteModalOpen(false)}
                    contentLabel="IP 차단 확인"
                    className="modalPop confirmPop"
                    overlayClassName="overlay"
                >
                    <div className='modalTitle'>
                        <h2>IP 차단 하기</h2>
                    </div>
                    <div className='modalCont'>
                        <p><strong className='blueText'>{selectedIpForModal}</strong>을 차단하시겠습니까?</p>
                        <div className='modalBtnWrap'>
                            <button onClick={confirmDeleteSelected} className='btnSmall navyBtn'><span>확인</span></button>
                            <button onClick={() => setIsDeleteModalOpen(false)} className='btnSmall'><span>취소</span></button>
                        </div>
                    </div>
                </Modal>

                {/* 접속 허용 확인 모달 */}
                <Modal
                    isOpen={isAddModalOpen}
                    onRequestClose={() => setIsAddModalOpen(false)}
                    contentLabel="IP 접속 허용 확인"
                    className="modalPop confirmPop"
                    overlayClassName="overlay"
                >
                    <div className='modalTitle'>
                        <h2>IP 접속 허용</h2>
                    </div>
                    <div className='modalCont'>
                        <p><strong className='blueText'>{selectedIpForModal}</strong>의 접속을 허용하시겠습니까?</p>
                        <div className='modalBtnWrap'>
                            <button onClick={confirmAddIp} className='btnSmall navyBtn'><span>확인</span></button>
                            <button onClick={() => setIsAddModalOpen(false)} className='btnSmall'><span>취소</span></button>
                        </div>
                    </div>
                </Modal>

            </div>

        </div>
    );
}

export default AccessPermission;
