// src/pages/ScholarInfo/ScholarInfo.js
import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ScholarList from './ScholarList';
import ScholarForm from './ScholarForm';
import ScholarDetail from './ScholarDetail';
import ScholarEdit from './ScholarEdit';

function ScholarInfo() {
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (formData) => {
    try {
      // 예시 API 요청
      const response = await fetch('/api/scholar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Form submitted successfully:', formData);
        setSubmitStatus('success');
        // 추가적인 로직(예: 페이지 이동)
      } else {
        console.error('Form submission failed:', response.statusText);
        setSubmitStatus('failure');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
  };

  return (
    <div className='inner'>
      {submitStatus === 'success' && <p>Form submitted successfully!</p>}
      {submitStatus === 'failure' && <p>Form submission failed. Please try again.</p>}
      {submitStatus === 'error' && <p>An error occurred during form submission.</p>}

      <Routes>
        {/* 더 구체적인 라우트 먼저 선언 */}
        <Route path="list" element={<ScholarList />} />
        <Route path="write" element={<ScholarForm handleSubmit={handleSubmit} />} />
        <Route path=":id/edit" element={<ScholarEdit handleSubmit={handleSubmit} />} />
        <Route path=":id" element={<ScholarDetail />} />

        {/* 잘못된 경로 처리 */}
        <Route path="*" element={<Navigate to="/error" replace />} />
      </Routes>
    </div>
  );
}

export default ScholarInfo;
