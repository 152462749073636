//src/pages/ScholarInfo/VictimSearchModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import axios from '../../AxiosConfig';

const VictimSearchModal = ({ isOpen, onClose, onSelectVictim, initialSearchName }) => {
    const [victimList, setVictimList] = useState([]);

    useEffect(() => {
        if(isOpen && initialSearchName){
            fetchVictims();
        }
    }, [isOpen]);

    const fetchVictims = async (name = initialSearchName) => {
        try {
            const response = await axios.get(`/api/victims`, {
              params: {
                page : 1,
                page_size : 999,
                name,
              },
              headers: {
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`
              },
            });
            console.log(response);
            setVictimList(response.data.result.victims);
          } catch (err) {
            console.log("err", err);
          }
    };

    const handleSelect = (victim) => {
        onSelectVictim(victim);
    };

    return (        
        <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="피해자 검색" id='victimScList' className='modalPop'>
            <div className='modalWrap' >
                <div className='modalTitle'>
                    <h2>피해자 선택</h2>
                </div>
                <div className='modalCont'>
                    <div className='vcSearchResult'>
                        <table className='formTable textCenter'>
                            <thead>
                                <tr>                                
                                    <th>이름</th>
                                    <th>생년월일</th>
                                    <th>사고일시</th>
                                    <th>피해정도</th>
                                    <th>선택</th>
                                </tr>
                            </thead>
                            <tbody>
                                {victimList.map(victim => (
                                    <tr key={victim.idx}>                                    
                                        <td>{victim.name}</td>
                                        <td>{victim.birth_date}</td>
                                        <td>
                                        { victim.last_accident_date
                                        ? dayjs(victim.last_accident_date).format('YYYY-MM-DD HH:mm')
                                        : '-'}
                                        </td>
                                        <td>{victim.last_damage_level}</td>
                                        <td><button onClick={() => handleSelect(victim)} className='btnSelect'>선택</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='modalBtnWrap'>
                        <button onClick={onClose} className="btnSmall">닫기</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default VictimSearchModal;
