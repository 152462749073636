import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from '../../AxiosConfig';

const FileUploadModal = ({ isOpen, onRequestClose, uploadEndpoint, onUploadSuccess }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('excel_file', selectedFile);

            try {
                const response = await axios.post(uploadEndpoint, formData, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 201) { //201 created
                    alert("업로드 성공");
                    if (onUploadSuccess) {
                        onUploadSuccess();
                    }
                } else {
                    alert("업로드 실패");
                }
            } catch (error) {
                console.error("파일 업로드 중 오류 발생:", error);
                alert("업로드 중 오류가 발생했습니다.");
            }

            onRequestClose();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="엑셀 업로드"
            className='modalPop fileUploadModal'
        >
            <div className='modalWrap'>
                <div className='modalTitle'>
                    <h2>엑셀 파일 업로드</h2>
                </div>
                <div className='modalCont'>
                    <div className='fileUpload'>
                        <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
                    </div>
                    <div className='modalBtnWrap'>
                        <button onClick={handleUpload} className='btnSmall navyBtn'>업로드</button>
                        <button onClick={onRequestClose} className="btnSmall">닫기</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FileUploadModal;
