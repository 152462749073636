//src/pages/Login.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from '../AxiosConfig';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import '../assets/style/login.css';
import logo from '../assets/images/logo_white.png';

Modal.setAppElement('#root');

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [activeInput, setActiveInput] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [loginError, setLoginError] = useState('');

  const handleLogin = async () => {
    //e.preventDefault();
    try {
      // axios 요청을 보내서 로그인 처리
      const response = await axios.post('/api/login', {
        username,
        password
      });
      //respone.data.code가 200이면 로그인 성공
      if(response.data.code === 200){
        //reaponse.data.otp_required가 true이면 인증번호 입력 모달창 띄우기
        if(response.data.otp_required){
          setIsModalOpen(true);
          setLoginError('');
        }else{
          login(response.data.access_token,response.data.refresh_token);
          navigate('/');
        }
      }else{
        setLoginError('아이디 또는 비밀번호가 잘못되었습니다.');
      }
    } catch (error) {
      console.log(error)
      setLoginError('아이디 또는 비밀번호가 잘못되었습니다.');
    }
  };

  const handleVerificationSubmit = async () => {
    //인증번호 확인
    try {
      const response = await axios.post('/api/otp', {
        username,
        "otp_code": verificationCode
      });
      if(response.data.code === 200){
        setIsModalOpen(false);
        login(response.data.access_token,response.data.refresh_token);
        navigate('/');
      } else {
        setError('잘못된 인증번호입니다.');
      }
    } catch (error) {
      console.error(error);
      setError('잘못된 인증번호입니다.');
    }
  };

  const handleResendCode = () => {
    //sendVerificationCode();
    handleLogin();
    setVerificationCode('');
    setError('');
  };

  const handleFocus = (inputName) => {
    setActiveInput(inputName);
    if (loginError) setLoginError('');
  };

  const handleBlur = () => {
    setActiveInput(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (isModalOpen) {
        handleVerificationSubmit();
      } else {
        handleLogin();
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setVerificationCode('');
    setError('');
  };

  return (
    <div className='loginPage'>
      <div className='inner'>
        <div className='left'>
          <div className='loginFormWrap'>
            <h2 className='loginTit'>
              <img src={logo} alt='고속도로장학재단' />
              <p>개인정보처리시스템</p>
            </h2>
            <div className='loginForm'>
              <div className={`inputWrap ${activeInput === 'username' ? 'active' : ''}`}>
                <label htmlFor='username'><span className='hidden'>아이디</span></label>
                <input
                  type='text'
                  id='username'
                  placeholder='아이디를 입력하세요'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onFocus={() => handleFocus('username')}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className={`inputWrap ${activeInput === 'password' ? 'active' : ''}`}>
                <label htmlFor='password'><span className='hidden'>비밀번호</span></label>
                <input
                  type='password'
                  id='password'
                  placeholder='비밀번호를 입력하세요'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => handleFocus('password')}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className='errorBox'>{loginError && <p className="loginError">{loginError}</p>}</div>
              <button onClick={handleLogin} className='loginBtn'>로그인</button>
            </div>
          </div>
        </div>
        <div className='right'>
          <p className='loginImg'></p>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="인증번호 입력"
        className="verificationModal"
        overlayClassName="verificationOverlay"
      >
        <div className='modalWrap'>
          <h2>휴대폰으로 전송된 인증번호를 입력하시고<br/>인증 완료 버튼을 클릭하세요.</h2>
          <div className='verificationInput'>
            <label htmlFor="verificationCode">
              <span className='hidden'>인증번호</span>
            </label>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="인증번호 입력"
              autoComplete="off"
              onKeyDown={handleKeyDown}
            />
            <button onClick={handleResendCode} className='btnResend'>재전송</button>
          </div>
          <div className='errorBox'>{error && <p className="error">{error}</p>}</div>
          <div className='modalBtnWrap'>
            <button onClick={handleVerificationSubmit} className='btnVerification'>인증완료</button>
          </div>
          <button onClick={closeModal} className='btnClose'><span className='hidden'>닫기</span></button>
        </div>
      </Modal>
    </div>
  );
}

export default Login;
