import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import { ko } from 'date-fns/locale';
import { RadioGroup, FormControlLabel, Radio, FormLabel, FormControl } from '@mui/material';

const SearchForm = ({ onSearch }) => {
  const [type, setType] = useState('program');
  
  const today = new Date();
  const defaultStartDate = new Date(today);
  defaultStartDate.setMonth(today.getMonth() - 3);

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(today);
  const [selectedButton, setSelectedButton] = useState('3개월');

  const setDateRange = (monthsAgo, buttonLabel) => {
    const start = new Date(today);
    start.setMonth(today.getMonth() - monthsAgo);

    setStartDate(start);
    setEndDate(today);
    setSelectedButton(buttonLabel);
  };

  const changeStartDate = (date) => {
    setStartDate(date);
    setSelectedButton('');
  }

  const changeEndDate = (date) => {
    setEndDate(date);
    setSelectedButton('');
  }

  const handleSearch = (e) => { 
    e.preventDefault();

    const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] : '';
    const formattedEndDate = endDate ? endDate.toISOString().split('T')[0] : '';

    onSearch(type, formattedStartDate, formattedEndDate);
  };

  return (
    <form onSubmit={handleSearch} className="statistics-search-form">
      <fieldset className="statisSearchWrap">
        <legend className="visually-hidden">통계 검색 폼</legend>
        <div className="searchType">
          <span className="tit">통계 유형</span>
          <div className="cnts">
            <FormControl component="fieldset" className="radioGroup">
              <FormLabel component="legend" id="stat-type-label" className="hidden">통계 유형</FormLabel>
              <RadioGroup
                aria-labelledby="stat-type-label"
                name="statType"
                value={type}
                onChange={(e) => setType(e.target.value)}
                row
              >
                <FormControlLabel value="program" control={<Radio />} label="프로그램별 통계" disableTypography/>
                <FormControlLabel value="year" control={<Radio />} label="연도별 통계" disableTypography/>
                <FormControlLabel value="scholar" control={<Radio />} label="장학생별 통계" disableTypography/>
                <FormControlLabel value="grade" control={<Radio />} label="학년별 통계" disableTypography/>
                <FormControlLabel value="victim" control={<Radio />} label="피해자별 통계" disableTypography/>
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className="searchType">
          <span className="tit">기간 검색</span>
          <div className="cnts">
            <div className="dateButtons">
              <button 
                type="button" 
                onClick={() => setDateRange(3, '3개월')} 
                className={`dateButton ${selectedButton === '3개월' ? 'selected' : ''}`}
              >
                3개월
              </button>
              <button 
                type="button" 
                onClick={() => setDateRange(6, '6개월')} 
                className={`dateButton ${selectedButton === '6개월' ? 'selected' : ''}`}
              >
                6개월
              </button>
              <button 
                type="button" 
                onClick={() => setDateRange(12, '1년')} 
                className={`dateButton ${selectedButton === '1년' ? 'selected' : ''}`}
              >
                1년
              </button>
            </div>

            <label htmlFor="start-date" className="hidden">시작일</label>
            <DatePicker
              locale={ko}
              id="start-date"
              selected={startDate}
              onChange={(date) => changeStartDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="시작 날짜 선택"
              aria-required="true"
              maxDate={new Date()} // 종료일이 미래 날짜가 되지 않도록
            />
            {' ~ '}
            <label htmlFor="end-date" className="hidden">종료일</label>
            <DatePicker
              locale={ko}
              id="end-date"
              selected={endDate}
              onChange={(date) => changeEndDate(date)}
              dateFormat="yyyy-MM-dd"
              placeholderText="종료 날짜 선택"
              aria-required="true"
              minDate={startDate} // 시작일보다 이른 날짜가 선택되지 않도록
              maxDate={new Date()} // 미래 날짜가 선택되지 않도록
            />

            <button type="submit" aria-label="통계 검색 버튼" className="searchBtn"><span>검색</span></button>
          </div>

        </div>

      </fieldset>
    </form>
  );
};

export default SearchForm;
