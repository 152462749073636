import axios from './AxiosConfig';

export const fetchOptions = async (type) => {
  // type에 따라 어떤 field_name을 사용할지 정합니다.
  let field_name = '';
  if(type === 1){ //프로젝트
    field_name = 'project';
  }else if(type === 2){
    field_name = 'education_level';
  }else if(type === 3){
    field_name = 'academic_status';
  }else if(type === 4){
    field_name = 'economic_status';
  }else if(type === 5){
    field_name = 'school_type';
  }else if(type === 6){ //가족관계
    field_name = 'relationship';
  }else if(type === 7){ //피해 정도
    field_name = 'damage_level';
  }else{
    return [];
    // throw new Error('Invalid type');
  }

  try {
    const response = await axios.get(`/api/options/${field_name}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`
      },
    });
    //response.data.options 에서 idx 를 value로, value를 label로 전달합니다.
    return response.data.options.map(option => {
      return {
        label: option.value,
        value: option.idx
      }
    });
  } catch (error) {
    console.error('Failed to fetch options:', error);
    throw error; // 에러를 호출한 쪽에서 처리할 수 있도록 던집니다.
  }
};