import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import axios from '../../AxiosConfig';
import { fetchOptions } from '../../fetchOptions';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';

const VictimEdit = ({ handleSubmit }) => {
  const { id } = useParams();
  const [accidentInfo, setAccidentInfo] = useState({});
  const [familyMembers, setFamilyMembers] = useState([]);
  const [supportInfo, setSupportInfo] = useState([]);
  const [severityOptions, setSeverityOptions] = useState([]);
  const [relationOptions, setRelationOptions] = useState([]);
  const severitySelectRef = useRef(null);
  const relationSelectRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const severityOptionsData = await fetchOptions(7); // 7: 피해 정도
        const relationOptionsData = await fetchOptions(6); // 6: 가족관계

        setSeverityOptions(severityOptionsData);
        setRelationOptions(relationOptionsData);

        const response = await axios.get(`/api/victim/${id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`
          },
        });
        
        const accidentData = response.data.result.accidents[0] || {};
        setAccidentInfo({
          ...accidentData,
          accident_date: accidentData.accident_date ? dayjs(accidentData.accident_date).format('YYYY.MM.DD HH:mm') : '',
        });
        setFamilyMembers(response.data.result.families.map(family => ({
          ...family,
          birth_date: family.birth_date ? dayjs(family.birth_date).format('YYYY.MM.DD') : '',
        })));
        setSupportInfo(response.data.result.subsidies.map(support => ({
          ...support,
          payment_date: support.payment_date ? dayjs(support.payment_date).format('YYYY.MM.DD') : '',
          amount: formatCurrency(support.amount),
        })));
      } catch (err) {
        if (err.response && err.response.status === 404) {
          alert('피해자 정보를 찾을 수 없습니다.');
          navigate('/victim-info/list');
        }
      }
    };

    fetchInitialData();
  }, [id, navigate]);

  useEffect(() => {
    const checkComplete = (selector) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        const input = element.querySelector('input, textarea, .sv-select__control');
        if (input) {
          if (input.tagName === 'DIV' && input.classList.contains('sv-select__control')) {
            const selectValue = input.querySelector('.sv-select__single-value');
            if (selectValue && selectValue.textContent) {
              element.classList.add('complete');
            } else {
              element.classList.remove('complete');
            }
          } else {
            if (input.value) {
              element.classList.add('complete');
            } else {
              element.classList.remove('complete');
            }
          }
        }
      });
    };

    checkComplete('.inputWrap');
    checkComplete('.textWrap');
    checkComplete('.selectWrap');
  }, [accidentInfo, familyMembers, supportInfo]);

  const handleChange = (e, setState, index) => {
    const { name, value } = e.target;
    setState((prevState) => {
      if (typeof index === 'number') {
        const newState = [...prevState];
        newState[index][name] = value;
        return newState;
      } else {
        return { ...prevState, [name]: value };
      }
    });
  };

  const handleFamilyMemberChange = (e, index) => {
    handleChange(e, setFamilyMembers, index);
  };

  const resetForm = () => {
    setAccidentInfo({});
    setFamilyMembers([]);
    setSupportInfo([]);
    document.querySelectorAll('.complete').forEach(el => el.classList.remove('complete'));
    navigate(-1); // 이전 페이지로 이동
  };

  const validateInputs = () => {
    // 1. 각 항목 필수 입력 여부 확인
    if (!accidentInfo.accident_date || !accidentInfo.accident_place || !accidentInfo.damage_level || !accidentInfo.accident_desc) {
      alert("사고 정보의 모든 필수 항목을 입력해 주세요.");
      return false;
    }

    const dateTimeRegex = /^\d{4}\.\d{2}\.\d{2} \d{2}:\d{2}$/;
    const dateRegex = /^\d{4}.\d{2}.\d{2}$/;

    // 2. 사고일시 형식 확인 ('2024.08.07 18:42')
    if (!dateTimeRegex.test(accidentInfo.accident_date)) {
      alert("사고일시를 'YYYY.MM.DD HH:mm' 형식으로 입력해 주세요.");
      return false;
    }

    // 3. 가족현황의 생년월일 형식 확인 ('1994.01.23')
    for (let i = 0; i < familyMembers.length; i++) {
      if (!familyMembers[i].name || !familyMembers[i].birth_date || !familyMembers[i].relationship) {
        alert("가족현황의 모든 필수 항목을 입력해 주세요.");
        return false;
      }
      if (!dateRegex.test(familyMembers[i].birth_date)) {
        alert(`생년월일을 'YYYY.MM.DD' 형식으로 입력해 주세요.\n(가족 구성원: ${familyMembers[i].name})`);
        return false;
      }
    }

    // 4. 재활보조금 지급현황의 지급일 형식 확인 ('2024.08.07')
    for (let i = 0; i < supportInfo.length; i++) {
      if (!supportInfo[i].payment_date) {
        alert("재활보조금 지급일을 입력해 주세요.");
        return false;
      }
      if (supportInfo[i].amount === '') {
        alert("재활보조금 지급금액을 입력해 주세요.");
        return false;
      }
      if (!dateRegex.test(supportInfo[i].payment_date)) {
        alert(`지급일을 'YYYY.MM.DD' 형식으로 입력해 주세요.\n(지급현황: ${i + 1})`);
        return false;
      }
    }
    
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    
    // 입력 검증
    if (!validateInputs()) {
      return;
    }
  
    // 1. 사고일시 포맷팅 (YYYY-MM-DD HH:mm:ss)
    const formattedAccidentInfo = {
      accident_date: accidentInfo.accident_date ? dayjs(accidentInfo.accident_date, 'YYYY.MM.DD HH:mm').format('YYYY-MM-DD HH:mm:ss') : '',
      accident_place: accidentInfo.accident_place,
      accident_desc: accidentInfo.accident_desc,
      damage_level: accidentInfo.damage_level,
      etc: accidentInfo.etc || ''
    };
  
    // 2. 가족현황 생년월일 포맷팅 (YYYY-MM-DD)
    const formattedFamilyMembers = familyMembers.map(member => ({
      name: member.name,
      birth_date: member.birth_date ? dayjs(member.birth_date).format('YYYY-MM-DD') : '',
      relationship: member.relationship,
      etc: member.etc || ''
    }));
  
    // 3. 재활보조금 지급현황 포맷팅
    const formattedSupportInfo = supportInfo.map(support => ({
      payment_date: support.payment_date ? dayjs(support.payment_date).format('YYYY-MM-DD') : '',
      amount: parseFloat(support.amount.replace(/,/g, '')),
      etc: support.etc || ''
    }));
  
    // 4. 서버로 데이터 전송
    try {
      const response = await axios.put(`/api/victim/update/${id}`, {
        accidents: [formattedAccidentInfo],
        families: formattedFamilyMembers,
        subsidies: formattedSupportInfo
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        }
      });
  
      if (response.status === 200) {
        alert("수정이 완료되었습니다.");
        navigate('/victim-info/list');
      }
    } catch (error) {
      console.error("Error updating victim info:", error);
      alert("수정 중 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  const handleFocus = (e) => {
    e.target.closest('.inputWrap, .textWrap').classList.add('active');
  };

  const handleBlur = (e) => {
    e.target.closest('.inputWrap, .textWrap').classList.remove('active');
  };

  const addFamilyMember = () => {
    setFamilyMembers([...familyMembers, { name: '', birth_date: '', relationship: '', etc: '' }]);
  };
  
  const addSupportInfo = () => {
    setSupportInfo([...supportInfo, { payment_date: '', amount: '', etc: '' }]);
  };

  const removeSupportInfo = (index) => {
    setSupportInfo(supportInfo.filter((_, i) => i !== index));
  };

  const removeFamilyMember = (index) => {
    setFamilyMembers(familyMembers.filter((_, i) => i !== index));
    relationSelectRefs.current.splice(index, 1);
  };

  const formatCurrency = (value) => {
    if (!value) return 0;

    const num = parseFloat(value.replace(/,/g, ''));
    if (isNaN(num)) return 0;
    
    return num.toLocaleString('ko-KR', {
      maximumFractionDigits: 0,
    });
  };

  const handleSupportInfoChange = (e, index) => {
    let { name, value } = e.target;

    if (name === 'amount') {
      const rawValue = value.replace(/,/g, '');
      if (!isNaN(rawValue)) {
        value = formatCurrency(rawValue);
      }
    }

    setSupportInfo(prevSupportInfo => {
      const newSupportInfo = [...prevSupportInfo];
      newSupportInfo[index][name] = value;
      return newSupportInfo;
    });
  };

  const handleSelectChange = (selectedOption, index, field) => {
    if (field === 'damage_level') {
      setAccidentInfo((prevState) => ({
        ...prevState,
        damage_level: selectedOption ? selectedOption.value : '',
        damage_level_display: selectedOption ? selectedOption.label : '',
      }));
    } else if (field === 'relation') {
      const newFamilyMembers = [...familyMembers];
      newFamilyMembers[index] = {
        ...newFamilyMembers[index],
        relationship_display: selectedOption ? selectedOption.label : '',
        relationship: selectedOption ? selectedOption.value : '',
      };
      setFamilyMembers(newFamilyMembers);
    }

    const selectWrap = document.querySelector(`.selectWrap.${field}-${index}`);
    if (selectWrap) {
      if (selectedOption) {
        selectWrap.classList.add('complete');
      } else {
        selectWrap.classList.remove('complete');
      }
    }
  };

  const getSelectedOption = (options, value) => {
    return options ? options.find(option => option.value == value) : null;
  };

  return (
    <div className='subPage'>
      <div className='subTitleWrap borderBtm'>
        <h1 className='subTitle'>피해자 정보수정</h1>
      </div>

      <div className='boardFormWrap'>
        <form onSubmit={onSubmit}>
          <div className='titleBox'>
            <h2 className='title01'>사고정보</h2>
            <p className='eqText'><span className='required'>*</span> 필수입력항목입니다.</p>
          </div>
          <div className='formBox'>
            <div className="inputWrap">
              <label>사고일시<span className='required'>*</span></label>
              <input
                type='text'
                name='accident_date'
                value={accidentInfo.accident_date || ''}
                onChange={(e) => handleChange(e, setAccidentInfo)}
                placeholder="2000.01.01 12:00"
                required
              />
            </div>
            <div className="inputWrap">
              <label>사고장소<span className='required'>*</span></label>
              <input
                type='text'
                name='accident_place'
                value={accidentInfo.accident_place || ''}
                onChange={(e) => handleChange(e, setAccidentInfo)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="사고장소를 입력하세요"
                required
              />
            </div>
            <div className="selectWrap severity-0">
              <label>피해정도<span className='required'>*</span></label>
              <Select
                options={severityOptions}
                value={getSelectedOption(severityOptions, accidentInfo.damage_level)}
                onChange={(selectedOption) => handleSelectChange(selectedOption, 0, 'damage_level')}
                placeholder="피해 정도 선택"
                classNamePrefix="sv-select"
                isSearchable={false}
                ref={severitySelectRef}
                menuPortalTarget={document.body}
                styles={{ 
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  control: base => ({ ...base, minHeight: '40px' }),
                }}
              />
            </div>
            <div className="textWrap">
              <label>사고내용<span className='required'>*</span></label>
              <textarea
                name='accident_desc'
                value={accidentInfo.accident_desc || ''}
                onChange={(e) => handleChange(e, setAccidentInfo)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="사고내용을 입력하세요"
                required
              />
            </div>
            <div className="textWrap">
              <label>기타</label>
              <textarea
                name='etc'
                value={accidentInfo.etc || ''}
                onChange={(e) => handleChange(e, setAccidentInfo)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder="기타내용을 입력하세요"
              />
            </div>
          </div>

          <div className='titleBox'>
            <h2 className='title01'>가족현황</h2>
          </div>
          <div className='formBox'>
            {familyMembers.map((member, index) => (
              <div key={index} className='familyMember'>
                <div className="inputWrap">
                  <label>이름<span className='required'>*</span></label>
                  <input
                    type='text'
                    name='name'
                    value={member.name}
                    onChange={(e) => handleFamilyMemberChange(e, index)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="이름을 입력하세요"
                    required
                  />
                </div>
                <div className="inputWrap">
                  <label>생년월일(8자리)<span className='required'>*</span></label>
                  <input
                    type='text'
                    name='birth_date'
                    value={member.birth_date}
                    onChange={(e) => handleFamilyMemberChange(e, index)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="2000.01.01"
                    required
                  />
                </div>
                <div className={`selectWrap relation-${index}`}>
                  <label>관계<span className='required'>*</span></label>
                  <Select
                    options={relationOptions}
                    value={getSelectedOption(relationOptions, member.relationship)}
                    onChange={(selectedOption) => handleSelectChange(selectedOption, index, 'relation')}
                    placeholder="관계 선택"
                    classNamePrefix="sv-select"
                    isSearchable={false}
                    ref={el => relationSelectRefs.current[index] = el}
                    menuPortalTarget={document.body}
                    styles={{ 
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
                <div className="inputWrap">
                  <label>기타</label>
                  <input
                    type='text'
                    name='etc'
                    value={member.etc}
                    onChange={(e) => handleFamilyMemberChange(e, index)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder="기타사항 입력"
                  />
                </div>
                <button type='button' onClick={() => removeFamilyMember(index)} className='rowDelete'>
                  삭제
                </button>
              </div>
            ))}
            <button type='button' onClick={addFamilyMember} className='rowAdd'>
              <span>가족사항 추가</span>
            </button>
          </div>
          
          <div className='titleBox'>
            <h2 className='title01'>재활보조금 지급현황</h2>
          </div>
          <div className='formBox'>
            {supportInfo.map((support, index) => (
              <div key={index} className='supportInfo'>
                <div className="inputWrap">
                  <label>지급일<span className='required'>*</span></label>
                  <input
                    type='text'
                    name='payment_date'
                    value={support.payment_date}
                    onChange={(e) => handleSupportInfoChange(e, index)}
                    placeholder="지급일을 입력하세요"
                    required
                  />
                </div>
                <div className="inputWrap">
                  <label>지급금액<span className='required'>*</span></label>
                  <input
                    type='text'
                    name='amount'
                    value={support.amount}
                    onChange={(e) => handleSupportInfoChange(e, index)}
                    placeholder="금액을 입력하세요"
                    required
                  />
                </div>
                <div className="inputWrap">
                  <label>기타</label>
                  <input
                    type='text'
                    name='etc'
                    value={support.etc || ''}
                    onChange={(e) => handleSupportInfoChange(e, index)}
                    placeholder="기타사항 입력"
                  />
                </div>
                <button type='button' onClick={() => removeSupportInfo(index)} className='rowDelete'>
                  삭제
                </button>
              </div>
            ))}
            <button type='button' onClick={addSupportInfo} className='rowAdd'>
              <span>재활보조금 추가</span>
            </button>
          </div>

          <div className='formActions'>
            <button type='submit' className='btnBig'>수정완료</button>
            <button type='button' onClick={resetForm} className='btnBig cancleBtn'>취소</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VictimEdit;
