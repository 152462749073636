import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import axios from '../../AxiosConfig';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Pagination from '../../components/Common/Pagination';
import FileUploadModal from '../../components/Common/FileUploadModal';
import Header from '../../components/Header';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchOptions } from '../../fetchOptions';

Modal.setAppElement('#root');

const VictimList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [victimList, setVictimList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const initialFetchDone = useRef(false);

  // 검색 필터링을 위한 상태
  const [filters, setFilters] = useState({
    victimName: '',
    startDate: null,
    endDate: null,
    subsidy : 'N'
  });

  const fetchVictims = async (page = currentPage, page_size = itemsPerPage) => {
    try {
      const payment_start = filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : null;
      const payment_end = filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : null;
      const response = await axios.get(`/api/victims`, {
        params: {
          page,
          page_size,
          name : filters.victimName,
          payment_start : payment_start,
          payment_end  : payment_end,
          subsidy: filters.subsidy
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        },
      });
      setVictimList(response.data.result.victims);
      setTotalItems(response.data.result.total_datas);
      setTotalPages(response.data.result.total_pages);
      setSelectedItems([]);
    } catch (err) {
      console.log("err", err);
    }
  };

  // 초기 로딩 시 데이터 불러오기
  useEffect(() => {
    if (!initialFetchDone.current) {
      fetchVictims();
      initialFetchDone.current = true;
    }
  }, []);

  const handleSearchSubmit = () => {
    setCurrentPage(1);
    fetchVictims(1, itemsPerPage);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchVictims(page, itemsPerPage);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value); 
    setCurrentPage(1);
    fetchVictims(1, e.target.value);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allIds = victimList.map((victim) => victim.idx);
      setSelectedItems(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  const handleDeleteSelected = () => {
    console.log(selectedItems);
    if (selectedItems.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteSelected = async () => {
    
    try {
      if (selectedItems.length === 1) {
          // 선택된 항목이 하나일 때
          const selectedId = selectedItems[0];
          await axios.delete(`/api/victim/delete/${selectedId}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            },
          });
      } else {
        // 선택된 항목이 여러 개일 때
        await axios.delete(`/api/victims/delete`, {
          data: {
              victim_ids: selectedItems,
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`
          },
        });
      }

      // 삭제 후 상태 업데이트 및 알림
      fetchVictims();
      setIsDeleteModalOpen(false);
      alert("선택한 항목이 삭제되었습니다.");
    } catch (err) {
        console.error("삭제 중 오류 발생:", err);
        alert("삭제 중 오류가 발생했습니다.");
    }
  };

  const handleUploadSuccess = () => {
    fetchVictims();
  };

  const handleDownload = async () => {

    try {
      const payment_start = filters.startDate ? dayjs(filters.startDate).format('YYYY-MM-DD') : "";
      const payment_end = filters.endDate ? dayjs(filters.endDate).format('YYYY-MM-DD') : "";
      const requestData = {
        name : filters.victimName,
        payment_start : payment_start,
        payment_end  : payment_end,
        subsidy: filters.subsidy
      };
      const response = await axios.post('/api/victims/download', requestData, {
        responseType: 'blob', // 서버에서 파일을 blob 형식으로 받을 것을 명시
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        }
      });

      // 파일 이름을 서버 응답의 Content-Disposition 헤더에서 추출 (옵션)
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'VictimList.xlsx';
      if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        const matches = /filename="(.+)"/.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1];
        }
      }

      // Blob 객체 생성
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });

      // 브라우저에서 파일 다운로드를 트리거
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      // 다운로드 후 링크 제거
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

    } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
        alert("파일 다운로드 중 오류가 발생했습니다.");
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;

  const handleSubsidyChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        subsidy: "Y"
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        subsidy: "N"
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleDateChange = (date, name) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: date
    }));
  };

  const handleSearch = () => {
    // 검색 로직 추가 (필터 정보를 API나 리스트 필터링에 사용)
    fetchVictims(1, itemsPerPage);
  };

  return (
    <div className='subPage'>
      <div className='subTitleWrap borderBtm'>
        <h1 className='subTitle'>피해자 목록</h1>
        <div className='rightBtnWrap'>
          <Link to='/victim-info/write' className='button navyBtn writeBtn'>
            <span>피해자 정보입력</span>
          </Link>
          <button className='button uploadBtn' onClick={() => setIsModalOpen(true)}>
            <span>엑셀 업로드</span>
          </button>
          <button className='button downBtn' onClick={handleDownload}>
            <span>엑셀 다운로드</span>
          </button>
        </div>
      </div>

      <div className="searchFilterBox">
        <div className="searchFilterItem">
          <span className='filterTit'>피해자 이름</span>
          <div className='filterBox'>
            <input
              type="text"
              className='inputText'
              name="victimName"
              value={filters.victimName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="searchFilterItem">
          <span className='filterTit'>지급일</span>
          <DatePicker
            selected={filters.startDate}
            onChange={(date) => handleDateChange(date, 'startDate')}
            dateFormat="yyyy-MM-dd"
            placeholderText="시작일"
            maxDate={new Date()}        // 오늘 날짜까지 선택 가능
          />
          <span> ~ </span>
          <DatePicker
            selected={filters.endDate}
            onChange={(date) => handleDateChange(date, 'endDate')}
            dateFormat="yyyy-MM-dd"
            placeholderText="종료일"
            minDate={filters.startDate} // 시작일 이후로만 종료일 선택 가능
            maxDate={new Date()}        // 오늘 날짜까지 선택 가능
          />
        </div>
        <div className="searchFilterItem w100">
          <span className='filterTit w80'>참여사업</span>
          <div className='filterBox'>
            <div key="Y">
              <label>
                <input
                  type="checkbox"
                  value="Y" 
                  onChange={handleSubsidyChange}
                />
                희망드림
              </label>
            </div>
          </div>
        </div>
        <button onClick={handleSearch} className="searchBtn"><span>검색</span></button>
      </div>

      <div className='boardListTop'>
        <div className='boardTopLeft'>
          <div className='pageNum'>
            <span>총 {totalItems}건 / {currentPage} 페이지</span>
          </div>
          <div className='pgNumSelect'>
            <label htmlFor='itemsPerPage' className='hidden'>
              페이지당 항목 수:{' '}
            </label>
            <select id='itemsPerPage' value={itemsPerPage} onChange={handleItemsPerPageChange}>
              <option value={5}>5개씩 보기</option>
              <option value={10}>10개씩 보기</option>
              <option value={20}>20개씩 보기</option>
            </select>
          </div>
          <button onClick={handleDeleteSelected} className='button'>
            <span>선택 삭제</span>
          </button>
        </div>
      </div>

      <div className='boardList'>
        <table className='boardTable'>
          <colgroup>
            <col style={{ width: '4%' }} />
            <col style={{ width: '6%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>
                <input
                  type='checkbox'
                  className='allSelect'
                  onChange={handleSelectAll}
                  checked={selectAll}
                />
              </th>
              <th className='listNum'>No</th>
              <th>이름</th>
              <th>생년월일</th>
              <th>사고일시</th>
              <th>피해정도</th>
            </tr>
          </thead>
          <tbody>
            {victimList.map((victim, index) => (
              <tr key={victim.idx}>
                <td>
                  <input
                    type='checkbox'
                    checked={selectedItems.includes(victim.idx)}
                    onChange={() => handleCheckboxChange(victim.idx)}
                  />
                </td>
                <td>{startIndex + index + 1}</td>
                <td>
                  <Link to={`/victim-info/${victim.idx}`}>{victim.name}</Link>
                </td>
                <td>{victim.birth_date}</td>
                <td>
                { victim.last_accident_date
                ? dayjs(victim.last_accident_date).format('YYYY-MM-DD HH:mm')
                : '-'}
                </td>
                <td>{victim.last_damage_level}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>

      <FileUploadModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        uploadEndpoint="/api/victims/upload"
        onUploadSuccess={handleUploadSuccess}
      />

      {/* 삭제 확인 모달 */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel='삭제 확인'
        className='modalPop confirmPop'
        overlayClassName='overlay'
      >
        <div className='modalTitle'>
          <h2>피해자 목록 선택 삭제</h2>
        </div>
        <div className='modalCont'>
          <p>
            삭제된 데이터는 복구되지 않습니다.
            <br />
            피해자 이력을 삭제하시겠습니까?
          </p>
          <div className='modalBtnWrap'>
            <button onClick={confirmDeleteSelected} className='btnSmall navyBtn'>
              <span>확인</span>
            </button>
            <button onClick={() => setIsDeleteModalOpen(false)} className='btnSmall'>
              <span>취소</span>
            </button>
          </div>
        </div>
      </Modal>
      
    </div>
  );
};

export default VictimList;
