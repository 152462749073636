import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import axios from '../../AxiosConfig';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Pagination from '../../components/Common/Pagination';
import Header from '../../components/Header';

Modal.setAppElement('#root');

const AccessList = () => {
  const [accessList, setAccessList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const initialFetchDone = useRef(false);

  const fetchAccessList = async (page = currentPage, page_size = itemsPerPage, ip = searchTerm) => {
    try {
      const response = await axios.get(`/api/access_log`, {
        params: {
          page,
          page_size,
          ip,
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("access_token")}`
        },
      });
      setAccessList(response.data.result.access_logs);
      setTotalItems(response.data.result.total_datas);
      setTotalPages(response.data.result.total_pages);
      setSelectedItems([]);
    } catch (err) {
      console.log("err", err);
    }
  };

  // 초기 로딩 시 데이터 불러오기
  useEffect(() => {
    if (!initialFetchDone.current) {
      fetchAccessList();
      initialFetchDone.current = true;
    }
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = () => {
    setCurrentPage(1);
    fetchAccessList(1, itemsPerPage, searchTerm);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchAccessList(page, itemsPerPage, searchTerm);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value); 
    setCurrentPage(1);
    fetchAccessList(1, e.target.value, searchTerm);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allIds = accessList.map((access) => access.idx);
      setSelectedItems(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  const handleDeleteSelected = () => {
    console.log(selectedItems);
    if (selectedItems.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteSelected = async () => {
    
  };

  const startIndex = (currentPage - 1) * itemsPerPage;

  return (
    <div className='subPage'>
        <div className='subTitleWrap borderBtm'>
            <h1 className='subTitle'>접속 이력</h1>
            <div className='rightBtnWrap'>
                <Link to="/access-history/permission" className='button navyBtn installBtn'><span>아이피 허용 관리</span></Link>
            </div>
        </div>

        <div className='boardListTop'>
            <div className='boardTopLeft'>
                <div className='pageNum'>
                    <span>총 {totalItems}건 / {currentPage} 페이지</span>
                </div>
                <div className='pgNumSelect'>
                    <label htmlFor="itemsPerPage" className='hidden'>페이지당 항목 수: </label>
                    <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        <option value={5}>5개씩 보기</option>
                        <option value={10}>10개씩 보기</option>
                        <option value={20}>20개씩 보기</option>
                    </select>
                </div>
                {/* <button onClick={handleDeleteSelected} className='button'><span>선택 삭제</span></button> */}
            </div>

            <div className='boardTopRight'>
                <div className='searchWrap'>
                    <div className='searchBox'>
                        <input
                            type="text"
                            placeholder="아이피 검색"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyPress}
                            className='inputkeyWord'
                        />
                        <button onClick={handleSearchSubmit} className='searchBtn'><span className="hidden">검색</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div className='boardList'>
            <table className='boardTable'>
                <colgroup>
                    {/* <col style={{ width: '4%' }} /> */}
                    <col style={{ width: '6%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '32%' }} />
                    <col style={{ width: '22%' }} />
                </colgroup>
                <thead>
                    <tr>
                        {/* <th><input type="checkbox" className='allSelect' onChange={handleSelectAll} checked={selectAll} /></th> */}
                        <th className='listNum'>No</th>
                        <th>IP주소</th>
                        <th>날짜</th>
                        <th>수행업무</th>
                        <th>수행대상</th>
                    </tr>
                </thead>
                <tbody>
                    {accessList.map((access, index) => (
                        <tr key={access.idx}>
                            {/* <td><input type="checkbox" checked={selectedItems.includes(access.idx)} onChange={() => handleCheckboxChange(access.idx)} /></td> */}
                            <td>{startIndex + index + 1}</td>
                            <td>{access.ip}</td>
                            <td>
                                { access.access_date
                                ? dayjs(access.access_date).format('YYYY-MM-DD HH:mm:ss')
                                : '-'}
                            </td>
                            <td>{access.action}</td>
                            <td>{access.target}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='boardBottomBtn'>
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>

        {/* 삭제 확인 모달 */}
        <Modal
            isOpen={isDeleteModalOpen}
            onRequestClose={() => setIsDeleteModalOpen(false)}
            contentLabel="삭제 확인"
            className="modalPop confirmPop"
            overlayClassName="overlay"
        >
            <div className='modalTitle'>
                <h2>접속 이력 삭제</h2>
            </div>
            <div className='modalCont'>
                <p>삭제된 데이터는 복구되지 않습니다.<br/>선택한 접속 이력을 삭제하시겠습니까?</p>
                <div className='modalBtnWrap'>
                    <button onClick={confirmDeleteSelected} className='btnSmall navyBtn'><span>확인</span></button>
                    <button onClick={() => setIsDeleteModalOpen(false)} className='btnSmall'><span>취소</span></button>
                </div>
            </div>
        </Modal>
    </div>
);
};

export default AccessList;
