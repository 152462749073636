//src/pages/AccessHistory/AccessHistory.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AccessList from './AccessList';
import AccessPermission from './AccessPermission';

function AccessHistory() {
  return (
    <div className='inner'>
      <Routes>
        <Route path="list" element={<AccessList/>} />
        <Route path="permission" element={<AccessPermission/>} />
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
    </div>
  );
}

export default AccessHistory;
