// src/pages/Statistics/ProgramStatistics.js
import React from 'react';

const ProgramStatistics = ({ results }) => {
    // 결과가 없는 경우 표시할 기본 데이터 설정
       
    return (
        <div className='statisticsPage'>
            <div className="titleBox">
                <h2 className="title01 blueText">프로그램별 통계</h2>
            </div>

            {/* 검색할 기간을 선택하지 않은 경우 메세지 출력 */}
            {results === null ? (
                <div className='textBox'>검색할 기간을 선택하세요.</div>
            ) : (
                <table className='formTable textCenter'>
                    <thead>
                        <tr>
                            <th>프로그램명</th>
                            <th>참여건수</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((result, index) => (
                            <tr key={index}>
                                <td>{result.project_name}</td>
                                <td>{result.participation_count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

        </div>
    );
};

export default ProgramStatistics;
