//src/pages/VictimInfo/VictimDetail.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from '../../AxiosConfig';

const VictimDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [victim, setVictim] = useState([]);
  const hasFetchedData = useRef(false);

  useEffect(() => {
      if (!id) {
        alert('잘못된 접근입니다.');
        window.location.href = '/';
        return;  // 로직을 더 진행하지 않도록 return 추가
      }

      if (!hasFetchedData.current) {
        const fetchData = async () => {
        try {
          hasFetchedData.current = true;
          const response = await axios.get(`/api/victim/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem("access_token")}`
            },
          });
          setVictim(response.data.result);
        } catch (err) {
          if (err.response.status === 404) {
            alert('피해자 정보를 찾을 수 없습니다.');
            navigate('/victim-info/list');
          }
        }
      };

      fetchData();
    }

  }, []);

  const getName = (families) => {
    const foundFamily = families.find(family => family.relationship_display === "본인");
    return foundFamily ? foundFamily.name : '';
  };

  const formatCurrency = (amount) => {
    if (typeof amount !== 'number') {
      amount = parseFloat(amount);
      if (isNaN(amount)) return '0';
    }
    
    return new Intl.NumberFormat('ko-KR', {
      style: 'decimal',
      currency: 'KRW',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  if (!victim) {
    return <div>Loading...</div>;
  }

  return (
    // victim 객체가 비어있는 경우 null을 반환하도록 변경
    <div className='subPage'>
      <div className='subTitleWrap borderBtm'>
        <h1 className='subTitle'>
          <strong className='blueText'>{victim.families ? getName(victim.families) : null}</strong>님 상세정보
        </h1>
      </div>

      <div className='boardDetailWrap'>

        <div className='titleBox'>
          <h2 className='title01'>사고정보</h2>
        </div>
        <table className='formTable'>
          <tbody>
            {victim.accidents && victim.accidents.map((accident, index) => (
              <React.Fragment key={accident.idx}>
              <tr>
                <th>사고일시</th>
                <td><span>
                { accident.accident_date
                ? dayjs(accident.accident_date).format('YYYY-MM-DD HH:mm')
                : '-'}
                </span></td>
                <th>사고장소</th>
                <td><span>{accident.accident_place}</span></td>
                <th>피해정도</th>
                <td><span>{accident.damage_level_display}</span></td>
              </tr>
              <tr>
                <th>사고내용</th>
                <td colSpan="5"><span>{accident.accident_desc}</span></td>
              </tr>
              <tr>
                <th>기타</th>
                <td colSpan="5"><span>{accident.etc}</span></td>
              </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>

        <div className='titleBox'>
          <h2 className='title01'>가족현황</h2>
        </div>
        <table className='formTable textCenter'>
          <thead>
            <tr>
              <th>이름</th>
              <th>생년월일</th>
              <th>관계</th>
              <th>기타</th>
            </tr>
          </thead>
          <tbody>
            {victim.families && victim.families.map((member, index) => (
              <tr key={member.idx}>
                <td>{member.name}</td>
                <td>{member.birth_date}</td>
                <td>{member.relationship_display}</td>
                <td>{member.etc}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='titleBox'>
          <h2 className='title01'>재활보조금 지급현황</h2>
        </div>
        <table className='formTable textCenter'>
          <thead>
            <tr>
              <th>지급일</th>
              <th>지급금액</th>
              <th>기타</th>
            </tr>
          </thead>
          <tbody>
            {victim.subsidies && victim.subsidies.map((support, index) => (
              <tr key={support.idx}>
                <td>{support.payment_date}</td>
                <td>{formatCurrency(support.amount)}</td>
                <td>{support.etc}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='formActions'>
          <button onClick={() => navigate('/victim-info/list')} className='btnBig'>목록보기</button>
          <button onClick={() => navigate(`/victim-info/${id}/edit`)} className='btnBig cancleBtn'>정보수정</button>
        </div>

      </div>
    </div>
  );
};

export default VictimDetail;
