// src/pages/Statistics/Statistics.js
import React, { useState } from 'react';
import axios from '../../AxiosConfig';
import SearchForm from './SearchForm';
import ProgramStatistics from './ProgramStatistics';
import YearStatistics from './YearStatistics';
import ScholarStatistics from './ScholarStatistics';
import GradeStatistics from './GradeStatistics';
import VictimStatistics from './VictimStatistics';

const Statistics = () => {
  const [selectedType, setSelectedType] = useState('program');
  const [searchResults, setSearchResults] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSearch = async (type, start, end) => {
    setSelectedType(type);
    //type 값이 없으면 searchResults를 초기화
    if (!type) {
      setSearchResults(null);
    }else{
      try {
        const response = await axios.get(`/api/statistics/${type}`, {
          params: {
            start_date : start,
            end_date : end
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`
          },
        });
        setSearchResults(response.data.result);
      } catch (err) {
        console.log("err", err);
      }
    }
    setStartDate(start);
    setEndDate(end);
  };

  const renderResults = () => {
    switch (selectedType) {
      case 'program':
        return <ProgramStatistics results={searchResults} />;
      case 'year':
        return <YearStatistics results={searchResults}/>;
      case 'scholar':
        return <ScholarStatistics results={searchResults} />;
      case 'grade':
        return <GradeStatistics results={searchResults} />;
      case 'victim':
        return <VictimStatistics results={searchResults} />;
      default:
        return null;
    }
  };

  return (
    <div className='inner'>
      <div className='subPage'>
        <div className="subTitleWrap borderBtm">
          <h1 className="subTitle">통계</h1>
        </div>

        <SearchForm onSearch={handleSearch} />
        {renderResults()}
      </div>
    </div>
  );
};

export default Statistics;
