import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

const instance = axios.create({
    //baseURL: 'http://192.168.0.20:8000/', // Django 서버 주소
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true, // 쿠키를 포함한 요청을 할 경우 설정
});

// Axios 응답 인터셉터 설정
instance.interceptors.response.use(
    (response) => {
      // 요청이 성공한 경우 그대로 반환
      return response;
    },
    (error) => {
      // 요청이 실패한 경우
      const { response } = error;

      if(response && (response.status === 401 || response.status === 403 || (response.data && response.data.code === 'token_not_valid') )) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
  
        // React Router의 useHistory 훅을 사용할 수 없으므로 window.location 사용
        window.location.href = '/'; // 로그아웃 후 로그인 페이지로 이동
      }
      return Promise.reject(error);
    }
);

export default instance;