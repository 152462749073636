// src/components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className='footer'>
      <p>copyright(c)고속도로장학재단. all rights reserved</p>
    </footer>
  );
}

export default Footer;
