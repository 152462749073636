// src/pages/Statistics/ScholarStatistics.js
import React from 'react';

const ScholarStatistics = ({ results }) => {

    const formatCurrency = (amount) => {
      if (typeof amount !== 'number') {
        amount = parseFloat(amount);
        if (isNaN(amount)) return '0';
      }
       
      return new Intl.NumberFormat('ko-KR', {
        style: 'decimal',
        currency: 'KRW',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount);
    };

    return (
        <div className='statisticsPage'>
            <div className="titleBox">
                <h2 className="title01 blueText">장학생별 통계</h2>
            </div>

            {results === null ? (
                <div className='textBox'>검색할 기간을 선택하세요.</div>
            ) : (
                <table className='formTable textCenter'>
                    <thead>
                        <tr>
                            <th>장학생 번호</th>
                            <th>장학생 이름</th>
                            <th>피해자 이름</th>
                            <th>장학금 지급 건수</th>
                            <th>장학금 지급액</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((result, index) => (
                            <tr key={index}>
                                <td>{result.scholar_id}</td>
                                <td>{result.scholar_name}</td>
                                <td>{result.victim_name}</td>
                                <td>{result.scholarship_payment_count}</td>
                                <td>{formatCurrency(result.scholarship_payment_amount)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

        </div>
    );
};

export default ScholarStatistics;
