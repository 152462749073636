import React from 'react';

const YearStatistics = ({ results }) => {

  const formatCurrency = (amount) => {
    if (typeof amount !== 'number') {
      amount = parseFloat(amount);
      if (isNaN(amount)) return '0';
    }
     
    return new Intl.NumberFormat('ko-KR', {
      style: 'decimal',
      currency: 'KRW',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };
  
  // 결과가 없는 경우 표시할 기본 데이터 설정
  return (
    <div className='statisticsPage'>
      <div className='titleBox'>
        <h2 className='title01 blueText'>연도별 통계</h2>
      </div>

      {results === null ? (
        <div className='textBox'>검색할 기간을 선택하세요.</div>
      ) : (
        <table className='formTable textCenter'>
          <thead>
            <tr>
              <th>연도</th>
              <th>장학생수</th>
              <th>장학급 지급 건수</th>
              <th>장학금 지급액</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result, index) => (
              <tr key={index}>
                <td>{result.year}</td>
                <td>{result.scholar_count}</td>
                <td>{result.scholarship_payment_count}</td>
                <td>{formatCurrency(result.scholarship_payment_amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default YearStatistics;
